import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");

const addProductSubCategories = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_PRODUCT_SUB_CATEGORY_ADD_ONE}`,
      {
        parentID: props.ParentID,
        name: props.Name,
        uuid: props.UUID,
        description: props.Description,
        CreatedBy: current_user,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    // if ((err.response.status = 404)) {
    //   // const navigate = useNavigate();
    //   alert(err);
    //   // navigate("/", { replace: true });
    // } have TypeError: Cannot set properties of undefined (setting 'status')
    alert(err);
    throw err;
  }
};

const getAllProductSubCategories = async () => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_PRODUCT_SUB_CATEGORY_GET_ALL}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    //console.log(err);
    // if ((err.response.status = 404)) {
    //   // const navigate = useNavigate();
    //   alert(err);
    //   // navigate("/", { replace: true });
    // } have TypeError: Cannot set properties of undefined (setting 'status')
    alert(err);
    throw err;
  }
};

const deleteSingeleProductSubCategory = async (props: any) => {
  // alert(props.id)
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_PRODUCT_SUB_CATEGORY_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      //alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const updateSingeleProductSubCategory = async (props: {
  ID: number;
  ParentID: number;
  UUID: String | number;
  Name: String;
  Description: String;
}) => {
  // alert(props.id)
  try {
    // alert(
    //   `ID==${props.ID} \n Name==${props.Name} \n Email==${props.Email} \n Address==${props.Address}`
    // );
    var req = await axios.put(
      `${process.env.REACT_APP_PRODUCT_SUB_CATEGORY_UPDATE_ONE}/${props.ID}`,
      {
        parentID: props.ParentID,
        name: props.Name,
        uuid: props.UUID,
        description: props.Description,
        // UpdatedBy: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    // if ((err.response.status = 404)) {
    //   // const navigate = useNavigate();
    //   //alert(err);
    //   // navigate("/", { replace: true });
    // }
    throw err;
  }
};

export {
  addProductSubCategories,
  getAllProductSubCategories,
  deleteSingeleProductSubCategory,
  updateSingeleProductSubCategory,
};
