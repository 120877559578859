import React, { useState } from "react";
import "./ForgotPassword.css";
//
import { useParams } from "react-router-dom";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//ant design
import { Spin } from "antd";
//api methods
import { ForgotUserPassword } from "../../../API/userAPI";

const ForgetPassword = () => {
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  //tenant uuid from the url
  type Params = {
    tenantUUID: string; // Or use '?' if it might be optional
  };
  let { tenantUUID } = useParams<Params>();

  //states
  const [TenantEmail, setTenantEmail] = useState("");
  // const [TenantUUID, setTenantUUID] = useState<String>(tenantUUID);
  //method to handle reset password
  const HandleResetPassword = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result: any = await ForgotUserPassword(
        tenantUUID as String,
        TenantEmail
      );
      notify_success(result.data);
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notify_error(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notify_error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        notify_error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  //
  const notify_error = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  const notify_success = async (message: any) =>
    toast.success(`Message: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });
  return (
    <div className="forgetMain">
      <ToastContainer />
      <div className="forgetContainer">
        <Spin spinning={loading}>
          <h1>Forgot Password</h1>
          <form onSubmit={HandleResetPassword}>
            <label>
              Your Email
              <input
                type="email"
                onChange={(e) => setTenantEmail(e.target.value)}
                required
              ></input>
            </label>
            <button type="submit">Reset Password</button>
          </form>
        </Spin>
      </div>
    </div>
  );
};

export default ForgetPassword;
