import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./Documentations.css";

const Documentations = () => {
  return (
    <div className="documentation-container">
      {/* Sidebar Navigation */}
      <div className="sidebar">
        <h2>Documentation</h2>
        <ul>
          <li>
            <Link to="docs/accounting">Accounting</Link>
          </li>
          <li>
            <Link to="docs/inventory">Inventory</Link>
          </li>
          <li>
            <Link to="docs/manufacturing">Manufacturing</Link>
          </li>
          {/* Add more links as needed */}
        </ul>
      </div>

      {/* Main Content Area */}
      <div className="main-content">
        <Routes>
          <Route path="/docs/accounting" element={<Accounting />} />
          <Route path="/docs/inventory" element={<Inventory />} />
          <Route path="/docs/manufacturing" element={<Manufacturing />} />
          {/* Add more Routes as needed */}
          <Route path="/" element={<h1>Welcome to the Documentation</h1>} />
        </Routes>
      </div>
    </div>
  );
};

// Placeholder Components for Documentation Sections
const Accounting = () => (
  <div>
    <h1>Accounting Documentation</h1>
    <p>Details about the accounting module.</p>
  </div>
);
const Inventory = () => (
  <div>
    <h1>Inventory Documentation</h1>
    <p>Details about the inventory module.</p>
  </div>
);
const Manufacturing = () => (
  <div>
    <h1>Manufacturing Documentation</h1>
    <p>Details about the manufacturing module.</p>
  </div>
);

export default Documentations;
