import React, { useEffect, useState } from "react";
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
  CheckAdminAccess,
} from "../../../../middleware/authorizationMiddleware";
//
// api methods
import {
  addProduct,
  getAllProductEditRequests,
  updateSingeleProduct,
  deleteSingeleProduct,
  SaveUpdateSingeleProductRequest,
  deleteSingeleProductEditRequest,
} from "../../../../API/productAPI";
import { getAllProductCategories } from "../../../../API/productcategoriesAPI";
import { getAllProductSubCategories } from "../../../../API/productsubcategoriesAPI ";
import { getAllSupplier } from "../../../../API/supplierAPI";
import { getAllWarehouse } from "../../../../API/warehouseAPI";
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//ant design
import { Spin } from "antd";
import { Modal as AntdModal } from "antd";
import type { RadioChangeEvent } from "antd";
import { Flex, Radio } from "antd";
import { Tooltip, Popover } from "antd";
//mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
import { Category, Description } from "@mui/icons-material";
import { relative } from "path";
//mui icons
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { useModuleContext } from "../../../../Contexts/ModuleContext";
//

// Define interface for product object
interface Product {
  id: number;
  Name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

const ListView = () => {
  //
  const location = useLocation();
  const { contextModuleId, moduleLink } = useModuleContext();
  // const moduleid = location.state?.moduleId;
  // console.log(moduleid);
  const navigate = useNavigate();
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //mui table state
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //product states
  const [productData, setproductData] = useState<Product[]>([]);

  //notice methods
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //

  //   Model methods
  const GetProducts = async () => {
    try {
      var req: any = await getAllProductEditRequests();
      setproductData(req.data);
      console.log(req.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetProducts();
  }, []);

  //   mui data grid methods
  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = async (id: Number | String) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      //
      setLoading(true);
      //
      const call = await deleteSingeleProductEditRequest({ id: id });
      console.log(call);
      GetProducts();
      notifySuccess("");
    } catch (err: any) {
      console.log(err);
      notifyError(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  //

  //mui data Columns
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "internalref", headerName: "Internal Ref", width: 100 },
    { field: "name", headerName: "Product Name", width: 130 },
    { field: "model", headerName: "Model", width: 130 },
    { field: "brand_name", headerName: "Brand Name", width: 130 },
    { field: "document", headerName: "Product Doc", width: 130 },
    { field: "description", headerName: "Product Description", width: 130 },
    { field: "image", headerName: "Product Image", width: 130 },
    {
      field: "quantity",
      valueGetter: (value, row) => {
        function sumQuantity() {
          let sum = 0;
          if (row.Product_Warehouses) {
            for (let i = 0; i < row.Product_Warehouses.length; i++) {
              sum += row.Product_Warehouses[i].quantity;
            }
          }
          return sum;
        }
        return `${sumQuantity()}`;
      },
      headerName: "On Hand",
      width: 80,
    },
    { field: "cost_price", headerName: "Cost Price", width: 100 },
    { field: "sales_price", headerName: "Sale Price", width: 100 },
    { field: "purchase_method", headerName: "Purchase Method", width: 100 },
    {
      field: "ProductCategorieId",
      headerName: "Product Categorie Id",
      width: 70,
    },
    {
      field: "ProductCategorieUUID",
      type: "string",
      valueGetter: (value, row) => {
        return `${row.ProductCategorie ? row.ProductCategorie.uuid : ""}`;
      },
      headerName: "PC UUID",
      width: 100,
    },
    {
      field: "ProductSubCategorieId",
      headerName: "Product Categorie Id",
      width: 70,
    },
    {
      field: "ProductSubCategorieUUID",
      valueGetter: (value, row) => {
        return `${row.ProductSubCategorie ? row.ProductSubCategorie.uuid : ""}`;
      },
      headerName: "ProductSubCategorieUUID",
      width: 100,
    },
    { field: "SupplierId", headerName: "Supplier ID", width: 70 },
    { field: "WarehouseId", headerName: "Warehouse ID", width: 70 },
    {
      field: "ProductCategorie",
      valueGetter: (value, row) => {
        return `${row.ProductCategorie ? row.ProductCategorie.name : ""}`;
      },
      headerName: "Categorie",
      width: 100,
    },
    {
      field: "ProductSubCategorie",
      valueGetter: (value, row) => {
        return `${row.ProductSubCategorie ? row.ProductSubCategorie.name : ""}`;
      },
      headerName: "SubCategorie",
      width: 100,
    },

    {
      field: "Supplier",
      valueGetter: (value, row) => {
        return `${row.Supplier ? row.Supplier.name : ""}`;
      },
      headerName: "Supplier",
      width: 100,
    },
    {
      field: "Warehouse",
      valueGetter: (value, row) => {
        return `${row.Warehouse ? row.Warehouse.name : ""}`;
      },
      headerName: "Warehouse",
      width: 100,
    },
    {
      field: "Product_Warehouses",
      valueGetter: (value, row) => {
        return `${row.Product_Warehouses ? row.Product_Warehouses : ""}`;
      },
      headerName: "Product Warehouse",
      width: 100,
    },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedBy", headerName: "Last Updated By", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return contextModuleId
          ? [
              CheckModuleEditAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() =>
                    navigate(`../productEditApproval`, {
                      state: { routeRecordId: id },
                    })
                  }
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),

              CheckModuleDeleteAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  // onClick={handleDeleteClick(id)}
                  onClick={() => {
                    AntdModal.confirm({
                      title: "Confirm",
                      content: "Are you sure you want to delete this record?",
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          {/* <Button>Custom Button</Button> */}
                          <CancelBtn />
                          <OkBtn />
                        </>
                      ),
                      onOk: () => {
                        async function asyncDeleteCall() {
                          await handleDeleteClick(id);
                        }
                        asyncDeleteCall();
                      },
                    });
                  }}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  // onClick={handleDeleteClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
            ]
          : [];
      },
    },
  ];
  //

  return (
    <div>
      <ToastContainer />
      <Spin spinning={loading}>
        <DataGrid
          rows={productData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                image: false,
                createdAt: false,
                createdBy: false,
                updatedAt: false,
                updatedBy: false,
                ProductCategorieId: false,
                // ProductCategorieUUID: false,
                ProductSubCategorieId: false,
                // ProductSubCategorieUUID: false,
                Product_Warehouses: false,
                SupplierId: false,
                WarehouseId: false,
                Warehouse: false,
                description: false,
                document: false,
                purchase_method: false,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </Spin>
    </div>
  );
};

export default ListView;
