import * as React from "react";
import { useNavigate } from "react-router-dom";
import { localStorage, sessionStorage } from "es-storage";
//mui
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
//mui icons
import NotificationsIcon from "@mui/icons-material/Notifications";
import AvatarLogo from "../../assest/Customer.png";
//auth middlewar
import { CheckAdminAccess } from "../../middleware/authorizationMiddleware";

//const pages = ["Notifications", "Pricing", "Blog"];
const pages = ["", "", ""];
//var pages: string[];
var settings = ["My Profile", "Settings", "Documentation", "Support", "Logout"];
//var settings: string[];

function ResponsiveAppBar() {
  //
  const userRole = sessionStorage.get("user_role");
  const username = sessionStorage.get("user_name");
  const companyname = sessionStorage.get("company_name");
  const userAvater = sessionStorage.get("user_image");
  //

  React.useEffect(() => {
    if (CheckAdminAccess()) {
      settings = [
        "My Profile",
        "Settings",
        "Documentation",
        "Support",
        "Logout",
      ];
    } else {
      settings = ["My Profile", "Documentation", "Support", "Logout"];
    }
  }, []);

  const navigate = useNavigate();
  //
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  //
  const handleLogout = async (event: any) => {
    event.preventDefault();
    try {
      sessionStorage.remove("HS_token");
      sessionStorage.remove("tenant_uuid");
      sessionStorage.clear();
      localStorage.clear();
    } catch (err) {
      console.log(err);
    }
  };
  //

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = async (event: any) => {
    const value = event.currentTarget.textContent;
    if (value === "Logout") {
      console.log(value);
      setAnchorElUser(null);
      try {
        let tuuid = sessionStorage.get("tenant_uuid");
        await handleLogout(event);
        navigate(`/${tuuid}`, { replace: true });
      } catch (err) {
        alert(err);
        console.log(err);
      }
    } else if (value === "My Profile") {
      console.log(value);
      setAnchorElUser(null);
      navigate("/landing/profile");
    } else if (value === "Settings") {
      console.log(value);
      setAnchorElUser(null);
      navigate("/landing/setting");
    } else if (value === "Documentation") {
      //console.log(value);
      setAnchorElUser(null);
      window.open("https://docs.habsync.com/");
    } else {
      setAnchorElUser(null);
    }
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/landing"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Home
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Home
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={userAvater} />
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={(Event) => handleCloseUserMenu(Event)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {username}({companyname})
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
