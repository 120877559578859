import * as React from "react";
import "./ListView.css";
import "../../../GlobalCss/Module/ListView/ListView.css";
import { useLocation, useNavigate } from "react-router-dom";
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
} from "../../../middleware/authorizationMiddleware";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Ant Design
import { UserOutlined } from "@ant-design/icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import { Tooltip } from "antd";
import { Spin } from "antd";
import { Checkbox } from "antd";
import type { GetProp } from "antd";
//ant design icons
import { UserAddOutlined, UserSwitchOutlined } from "@ant-design/icons";
//
import { useEffect, useState, useCallback } from "react";
//
import { Authorization } from "../../../middleware/authorizationMiddleware";
//
import { sessionStorage } from "es-storage";
//ant design
import { Modal, Space } from "antd";
//mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
//api methods
import {
  addUser,
  getAllUser,
  deleteSingeleUser,
  UpdateUserRBAC,
} from "../../../API/userAPI";
import { getAllTenantModule } from "../../../API/moduleAPI";
//
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
//
//
//
import {
  // GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  // GridToolbarContainer,
  GridActionsCellItem,
  // GridEventListener,
  GridRowId,
  // GridRowModel,
  // GridRowEditStopReasons,
  // GridSlots,
} from "@mui/x-data-grid";
import { Label, Update } from "@mui/icons-material";
import { create } from "domain";
import { useModuleContext } from "../../../Contexts/ModuleContext";

//

// Define interface for product object
interface User {
  id: number;
  fullName: string;
  email: string;
  phone: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  role: string;
}

export default function ListView() {
  //confirem delete state
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  //
  const navigate = useNavigate();
  const location = useLocation();
  const { contextModuleId, moduleLink } = useModuleContext();
  //const moduleid = location.state?.moduleId;
  // const [moduleid, setModuleId] = useState(location.state?.moduleId);

  // useEffect(() => {
  //   if (!moduleid && location.state?.moduleId) {
  //     setModuleId(location.state.moduleId);
  //   }
  // }, [location.state, moduleid]);
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //
  //
  const [userData, setUserData] = useState<User[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };

  //custom methods

  const GetUsers = useCallback(async () => {
    try {
      setLoading(true);
      var req: any = await getAllUser();
      setUserData(req.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []); // No dependencies needed for useCallback as it's not dependent on any external variables

  useEffect(() => {
    GetUsers();
  }, [GetUsers]); // Dependency is now the memoized GetUsers function, which won't change on re-renders

  // mui grid Methods
  const handleDeleteClick = async (id: Number | String) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      if (!id) {
        throw Error("No Record ID");
      }
      const call = await deleteSingeleUser(id);
      //console.log(call);
      GetUsers();
      notifySuccess(call.data || "");
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
        // console.log(error.request);
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message || "An unexpected error occurred.");
        //console.log("Error", error.message);
      } else {
        notifyError(error || "An unexpected error occurred.");
        alert(error);
        console.log("Error", error); // Log the entire error for debugging
      }
    }
  };
  //
  //
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "fullName", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 130 },
    { field: "phone", headerName: "Phone", width: 130 },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },
    { field: "role", headerName: "Role", width: 130 },
    { field: "last_login", headerName: "Last Login", width: 130 },
    //
    {
      field: "rbac_Customer",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Customer : ""}`;
      },
      headerName: "RBAC Customer",
      width: 100,
    },
    {
      field: "rbac_Dashboard",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Dashboard : ""}`;
      },
      headerName: "RBAC Dashboard",
      width: 100,
    },
    {
      field: "rbac_Discuss",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Discuss : ""}`;
      },
      headerName: "RBAC Discuss",
      width: 100,
    },
    {
      field: "rbac_Employee",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Employee : ""}`;
      },
      headerName: "RBAC Employee",
      width: 100,
    },
    {
      field: "rbac_Inventory",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Inventory : ""}`;
      },
      headerName: "RBAC Inventory",
      width: 100,
    },
    {
      field: "rbac_Supplier",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Supplier : ""}`;
      },
      headerName: "RBAC Supplier",
      width: 100,
    },
    {
      field: "rbac_Sales",
      valueGetter: (value, row) => {
        return `${row.RBACs[0] ? row.RBACs[0].Sales : ""}`;
      },
      headerName: "RBAC Sales",
      width: 100,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return contextModuleId
          ? [
              CheckModuleEditAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  onClick={() =>
                    navigate(`update`, { state: { routeUserId: id } })
                  }
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),

              CheckModuleDeleteAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  // onClick={handleDeleteClick(id)}
                  onClick={() => {
                    Modal.confirm({
                      title: "Confirm",
                      content: "Are you sure you want to delete this record?",
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          {/* <Button>Custom Button</Button> */}
                          <CancelBtn />
                          <OkBtn />
                        </>
                      ),
                      onOk: () => {
                        async function asyncDeleteCall() {
                          await handleDeleteClick(id);
                        }
                        asyncDeleteCall();
                      },
                    });
                  }}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  className="textPrimary"
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
            ]
          : [];
      },
    },
  ];

  // /////////////////////////////////////////////////
  return (
    <div>
      <ToastContainer />
      {/*  */}
      <Spin spinning={loading}>
        <DataGrid
          className="UserMngDataGrid"
          rows={userData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                rbac_Customer: false,
                rbac_Dashboard: false,
                // rbac_Discuss: false,
                rbac_Employee: false,
                rbac_Inventory: false,
                rbac_Supplier: false,
                rbac_Sales: false,
                // actions: Authorization(
                //   "Employee",
                //   sessionStorage.get("rbac_Employee"),
                //   ["Administrator", "Manager"]
                // )
                //   ? true
                //   : false,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </Spin>
    </div>
  );
}
