import * as React from "react";
import { useEffect, useState, useCallback } from "react";
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
} from "../../../middleware/authorizationMiddleware";
//
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
//ant design
import { Spin } from "antd";

//
import {
  getAllCustomer,
  updateSingeleCustomer,
  deleteSingeleCustomer,
} from "../../../API/customerAPI";
//

//mui data grid
//
import Box from "@mui/material/Box";
//import Button from "@mui/material/Button";
//import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import {
  // GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  // GridToolbarContainer,
  GridActionsCellItem,
  // GridEventListener,
  GridRowId,
  // GridRowModel,
  // GridRowEditStopReasons,
  // GridSlots,
} from "@mui/x-data-grid";
//

// Define interface for product object
interface User {
  id: number;
  name: string;
  // email: string;
  // createdAt: string;
  updatedAt: string;
  role: string;
}

//material ui modal
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//

export default function CustomerTable(props: any) {
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  const [customerData, setCustomerData] = useState<User[]>([]);
  //supplier form stats
  const [CustomerID, setCustomerID] = useState(0);
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerMobile, setCustomerMobile] = useState("");
  const [CustomerEmail, setCustomerEmail] = useState("");
  const [CustomerAddress, setCustomerAddress] = useState("");
  //mui grid
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //for modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //
  //
  const notifyErr = (msg: any) => toast.error(msg || "Error when delete!");
  const notifyDone = () => toast("Product deleted");
  //
  const notify_Error = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  // const notify_success = (message: any) =>
  //   toast.success(`Message: \n ${message}`, {
  //     position: "top-center",
  //     theme: "colored",
  //   });
  //

  const GetCustomers = useCallback(async () => {
    try {
      var req: any = await getAllCustomer();
      setCustomerData(req.data);
      console.log(customerData);
    } catch (err: any) {
      console.log(err);
      notify_Error(err.response.data);
    }
  }, []);

  useEffect(() => {
    GetCustomers();
  }, []);

  // Custome Functions
  const HandleEdit = async (event: any) => {
    event.preventDefault();
    try {
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      //
      setLoading(true);
      //
      const call = await updateSingeleCustomer({
        ID: CustomerID,
        Name: CustomerName,
        Mobile: CustomerMobile,
        Email: CustomerEmail,
        Address: CustomerAddress,
      });
      console.log(call);
      GetCustomers();
      notifyDone();
    } catch (err: any) {
      console.log(err);
      notifyErr(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    //setEditID(id.toString);
    const editedRow: any = customerData.find((row) => row.id === id);
    handleOpen();
    setCustomerID(editedRow.id);
    setCustomerName(editedRow.name);
    setCustomerAddress(editedRow.address);
    setCustomerEmail(editedRow.email);
    setCustomerMobile(editedRow.mobile);
    handleOpen();
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      const call = await deleteSingeleCustomer({ id: id });
      console.log(call);
      GetCustomers();
      notifyDone();
    } catch (err: any) {
      console.log(err);
      notifyErr(err.response.data);
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  //

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "mobile", headerName: "Phone", width: 130 },
    { field: "email", headerName: "Email", width: 130 },
    { field: "address", headerName: "Address", width: 130 },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedBy", headerName: "Updated By", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return props.ModuleID
          ? [
              CheckModuleEditAccess(props.ModuleID) ? (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
              CheckModuleDeleteAccess(props.ModuleID) ? (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
            ]
          : [];
      },
    },
  ];

  return (
    <div style={{ height: "85vh", width: "100%" }}>
      <ToastContainer />
      <DataGrid
        rows={customerData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />

      {/* for modal */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="ProductEditModalHeaderDiv">
                Customer Edit Form
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Spin spinning={loading}>
                <form onSubmit={HandleEdit}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className="productlabels">
                          Customer ID
                          <input
                            type="text"
                            placeholder=""
                            value={CustomerID}
                            required
                            readOnly
                          ></input>
                        </label>

                        <label className="productlabels">
                          Customer Name
                          <input
                            type="text"
                            placeholder=""
                            onChange={(event) =>
                              setCustomerName(event.target.value)
                            }
                            value={CustomerName}
                          ></input>
                        </label>

                        <label className="productlabels">
                          Customer Mobile
                          <input
                            type="tel"
                            placeholder=""
                            value={CustomerMobile}
                            onChange={(event) =>
                              setCustomerMobile(event.target.value)
                            }
                          ></input>
                        </label>

                        <label className="productlabels">
                          Customer Email
                          <input
                            type="email"
                            placeholder=""
                            value={CustomerEmail}
                            onChange={(event) =>
                              setCustomerEmail(event.target.value)
                            }
                          ></input>
                        </label>

                        <label className="productlabels">
                          Customer Address
                          <textarea
                            value={CustomerAddress}
                            onChange={(event) =>
                              setCustomerAddress(event.target.value)
                            }
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </div>
                  <button className="addbtn" type="submit">
                    Edit Customer
                  </button>
                </form>
              </Spin>
            </Typography>
          </Box>
        </Modal>
      </div>
      {/* modal */}
    </div>
  );
}
