import * as React from "react";
import "./ProductSale.css";
//pages
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
import UpdateView from "./UpdateView/UpdateView";
import ProductEditRequests from "../ProductEditApproval/ProductEditApproval";
import ProductEditApproval from "../ProductEditApproval/UpdateView/UpdateView";
//
import { useLocation, Routes, Route } from "react-router-dom";
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
  CheckAdminAccess,
} from "../../../middleware/authorizationMiddleware";
//
import { useEffect, useState } from "react";
//ant design
import { Spin } from "antd";
import type { RadioChangeEvent } from "antd";
import { Flex, Radio } from "antd";
import { Tooltip, Popover } from "antd";
//ant design icons
import { ProductOutlined } from "@ant-design/icons";
//
import { Base64 } from "js-base64";
//
import { useNavigate } from "react-router-dom";
//
import DocViewer from "../MyDocViewer/MyDocViewer";
//
import { localStorage, sessionStorage } from "es-storage";
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//mui
import { DataGrid, GridColDef } from "@mui/x-data-grid";
//Api
import {
  addProduct,
  getAllProduct,
  updateSingeleProduct,
  deleteSingeleProduct,
  SaveUpdateSingeleProductRequest,
} from "../../../API/productAPI";
import { getAllProductCategories } from "../../../API/productcategoriesAPI";
import { getAllProductSubCategories } from "../../../API/productsubcategoriesAPI ";
import { getAllSupplier } from "../../../API/supplierAPI";
import { getAllWarehouse } from "../../../API/warehouseAPI";
//midewares
import { Authorization } from "../../../middleware/authorizationMiddleware";
//material ui badge
import Badge from "@mui/material/Badge";
import FolderIcon from "@mui/icons-material/Folder";
import InventoryIcon from "@mui/icons-material/Inventory";
import SellIcon from "@mui/icons-material/Sell";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
//
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
//
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
import { Category, Description } from "@mui/icons-material";
import { relative } from "path";
import { useModuleContext } from "../../../Contexts/ModuleContext";
//

// Define interface for product object
interface Product {
  id: number;
  Name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

interface ProductCat {
  id: number;
  name: string;
  uuid: string;
}

interface TypeProductSubCategory {
  id: number;
  name: string;
  uuid: string | number;
  ProductCategorieId: number;
}

//material ui modal
const productEditModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  // height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleNewProductModel = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  // height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//

export default function ProductSale() {
  //
  //
  const location = useLocation();
  // const moduleid = location.state?.moduleId;
  const { contextModuleId, moduleLink } = useModuleContext();
  // const [moduleid, setModuleId] = useState(location.state?.moduleId);
  // console.log(moduleid);
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  const navigate = useNavigate();
  //ant design state
  const [RadioValue, setRadioValue] = useState(1);
  //
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (userRole == "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);
  //
  //
  const [productWarehouseData, setproductWarehouseData] = useState<any[]>([]);
  const [productSubCategorieData, setProductSubCategorieData] = useState([]);
  const [filterdSubCategorieData, setfilterdSubCategorieData] = useState<any[]>(
    []
  );
  const [productData, setproductData] = useState<Product[]>([]);
  const [selectedRows, setSelectedRows] = useState<Product[]>([]);
  //Edit variabls
  const EditSubCategoryArray: any = [];
  const [ProductID, setProductID] = useState(0);
  const [ProductName, setProductName] = useState("");
  const [EditProductInternalRef, setEditProductInternalRef] = useState("");
  const [EditProductCategorieID, setEditProductCategorieID] = useState("");
  const [EditProductSubCategorieID, setEditProductSubCategorieID] =
    useState("");
  const [EditProductSubCategorieUUID, setEditProductSubCategorieUUID] =
    useState("");
  const [EditProductWarehouseID, setEditProductWarehouseID] = useState("");
  const [EditProductModel, setEditProductModel] = useState("");
  const [EditProductBrand, setEditProductBrand] = useState("");
  const [EditProductCategoryUUID, setEditProductCategoryUUID] = useState("");
  const [EditedProductDoc, setEditedProductDoc] = useState("");
  const [EditedProductPurchasedMethod, setEditedProductPurchasedMethod] =
    useState("");
  const [EditedProductDescription, setEditedProductDescription] = useState("");
  const [EditProductQuantity, setEditProductQuantity] = useState("");
  const [EditProductCostPrice, setEditProductCostPrice] = useState("");
  const [EditProductSalePrice, setEditProductSalePrice] = useState("");
  const [EditProductSupplierID, setEditProductSupplierID] = useState("");
  const [EditProductImage, setEditProductImage] = useState("");
  const [ProductImage, setProductImage] = useState("");
  const [ProductCreatedBy, setProductCreatedBy] = useState("");
  const [ProductCreatedAt, setProductCreatedAt] = useState("");
  const [ProductUpdatedBy, setProductUpdatedBy] = useState("");
  const [ProductUpdatedAt, setProductUpdatedAt] = useState("");

  //New Product Variables
  const [NewProductName, setNewProductName] = useState("");
  const [NewProductImage, setNewProductImage] = useState("");
  const [NewProductDoc, setNewProductDoc] = useState("");
  const [NewProductDescription, setNewProductDescription] = useState("");
  const [NewProductQuantity, setNewProductQuantity] = useState("");
  const [NewProductPrice, setNewProductPrice] = useState("");
  const [NewProductCostPrice, setNewProductCostPrice] = useState("");
  const [NewProductPurchasedMethod, setNewProductPurchasedMethod] =
    useState("");
  const [NewProductModel, setNewProductModel] = useState("");
  const [NewProductBrand, setNewProductBrand] = useState("");
  const [NewProductCategoryUUID, setNewProductCategoryUUID] = useState("");
  const [NewProductSubCategoryUUID, setNewProductSubCategoryUUID] =
    useState("");
  const [NewProductInternalRef, setNewProductInternalRef] = useState("");
  const [NewProductDocument, setNewProductDocument] = useState("");

  //
  const [NewProductWarehouseID, setNewProductWarehouseID] = useState("");
  const [ProductWarehouseA, setProductWarehouseA] = useState([]);
  const ProductWarehouse: any = [];
  //
  //
  const [NewProductCategorieID, setNewProductCategorieID] = useState("");
  const [NewProductSubCategorieID, setNewProductSubCategorieID] = useState("");
  const [ProductCategorieA, setProductCategorieA] = useState([]);
  const ProductCategorie: any = [];
  //
  const [NewProductCategory, setNewProductCategory] = useState("");
  const [NewProductSupplierID, setNewProductSupplierID] = useState("");
  const [ProductSupplierA, setProductSupplierA] = useState([]);
  const ProductSupplier: any = [];
  //Product Badges Status
  const [ProductDocBadge, setProductDocBadge] = useState(0);
  const [ProductInventoryBadge, setProductInventoryBadge] = useState(0);
  const [ProductSoldBadge, setProductSoldBadge] = useState(0);
  const [ProductPurchasedBadge, setProductPurchasedBadge] = useState(0);
  //

  //for modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //
  //mui add model
  const [openAddModel, setOpenAddModel] = React.useState(false);
  const handleOpenAddModel = () => setOpenAddModel(true);
  const handleCloseAddModel = () => setOpenAddModel(false);
  //
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  // Notifications
  const notifyErr = (msg: any) => toast.error(msg || "Error!");
  const notifyDone = () => toast("Sucess");
  const notifyDone2 = () =>
    toast("Your Edit Product Request Sended Successfuly");

  const notifyError = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  const notify_success = (message: any) =>
    toast.success(`Message: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });
  //

  //ant desion methods
  const onRadioChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
  };
  //

  //Custom functions
  const handleAddProduct = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      var req = await addProduct({
        Name: NewProductName,
        InternalRef: NewProductInternalRef,
        Model: NewProductModel,
        Brand_Name: NewProductBrand,
        Purchase_Method: NewProductPurchasedMethod,
        Document: NewProductDocument,
        Description: NewProductDescription,
        Image: NewProductImage,
        Quantity: NewProductQuantity,
        Cost_Price: NewProductCostPrice,
        Sales_Price: NewProductPrice,
        Supplier_ID: NewProductSupplierID,
        Warehouse_ID: NewProductWarehouseID,
        ProductCategorie_ID: NewProductCategorieID,
        ProductSubCategorie_ID: NewProductSubCategorieID,
      });
      console.log(req);
      GetProducts();
      notify_success(req.data);
    } catch (err: any) {
      console.log(err);
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      }

      if (err.response.statusText) {
        notifyError(err.response.statusText);
      }
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const HandleGetSuppliers = async (e: any) => {
    e.preventDefault();
    try {
      const call = await getAllSupplier();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductSupplier.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductSupplier);
      setProductSupplierA(ProductSupplier);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetProductCategories = async (e: any) => {
    e.preventDefault();
    try {
      const call = await getAllProductCategories();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      //console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductCategorie.push({
          id: data[i].id,
          name: data[i].name,
          uuid: data[i].uuid,
        });
      }
      console.log(ProductCategorie);
      setProductCategorieA(ProductCategorie);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetProductSubCategories = async (e: any) => {
    e.preventDefault();
    try {
      const call: any = await getAllProductSubCategories();
      const data: any[] = call.data;
      for (let i = 0; i < data.length; i++) {
        EditSubCategoryArray.push({
          id: data[i].id,
          name: data[i].name,
          uuid: data[i].uuid,
        });
      }
      //console.log(EditSubCategoryArray);
      setProductSubCategorieData(call.data);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetWarehouses = async (e: any) => {
    e.preventDefault();
    try {
      const call = await getAllWarehouse();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductWarehouse.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductWarehouse);
      setProductWarehouseA(ProductWarehouse);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleEdit = async (event: any) => {
    event.preventDefault();
    try {
      //
      setLoading(true);
      //
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      const isApprovalRequired = JSON.parse(
        sessionStorage.get("product_edit_approval_required") || "false"
      );

      if (typeof isApprovalRequired !== "boolean") {
        const err = new Error("isApprovalRequired is not a boolean");
        throw err;
      }
      //
      if (!isApprovalRequired) {
        const call = await updateSingeleProduct({
          ID: ProductID,
          InternalRef: EditProductInternalRef,
          Name: ProductName,
          Model: EditProductModel,
          Brand_Name: EditProductBrand,
          Document: NewProductDoc,
          Description: EditedProductDescription,
          Image: EditProductImage,
          Quantity: EditProductQuantity,
          Cost_Price: EditProductCostPrice,
          Sales_Price: EditProductSalePrice,
          ProductCategorie_ID: EditProductCategorieID,
          ProductSubCategorie_ID: EditProductSubCategorieID,
          Supplier_ID: EditProductSupplierID,
          Purchase_Method: EditedProductPurchasedMethod,
          Warehouse_ID: EditProductWarehouseID,
        });
        console.log(call);
        GetProducts();
        notifyDone();
      }
      if (isApprovalRequired) {
        const call2 = await SaveUpdateSingeleProductRequest({
          ID: ProductID,
          InternalRef: EditProductInternalRef,
          Name: ProductName,
          Model: EditProductModel,
          Brand_Name: EditProductBrand,
          Document: NewProductDoc,
          Description: EditedProductDescription,
          Image: EditProductImage,
          Quantity: EditProductQuantity,
          Cost_Price: EditProductCostPrice,
          Sales_Price: EditProductSalePrice,
          ProductCategorie_ID: EditProductCategorieID,
          ProductSubCategorie_ID: EditProductSubCategorieID,
          Supplier_ID: EditProductSupplierID,
          Purchase_Method: EditedProductPurchasedMethod,
          Warehouse_ID: EditProductWarehouseID,
        });
        console.log(call2);
        //GetProducts();
        notifyDone2();
      }
    } catch (err: any) {
      console.log(err);
      notifyErr(err);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const handleDocumentChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        //setNewProductDoc(Base64.encode(reader.result as string));
        //setProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
    //converted the file into base64 format
    //setNewProductDoc(Base64.encodeURI(file));
  };

  const handleEditDocumentChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        //setNewProductDoc(Base64.encode(reader.result as string));
        setEditedProductDoc(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
    //converted the file into base64 format
    //setNewProductDoc(Base64.encodeURI(file));
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setNewProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setEditProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const handleProductDocChange = (e: any) => {
    // Check if file size exceeds 10 MB
    const file = e.target.files[0];
    if (file) {
      if (file.size > 10485760) {
        console.log(file.size);
        alert("doc file size exced 10MB");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setNewProductDocument(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };
  //filter subcategory based on parrent
  // const fillterSubCategory = async () => {
  //   if (ProductCategorieA && ProductCategorieA.length > 0) {
  //     const filteredArray = productSubCategorieData.filter(
  //       (category: any) => category.ProductCategorieId === NewProductCategorieID
  //     );
  //     setProductSubCategorieData(filteredArray);
  //   }
  // };
  // useEffect(() => {
  //   if (NewProductCategorieID) {
  //     fillterSubCategory();
  //   }
  // }, [NewProductCategorieID]);
  //

  // Function to update internal reference based on Model and Brand Name
  const updateInternalReference = () => {
    const internalRef = `${NewProductCategoryUUID}_${NewProductSubCategoryUUID}_${NewProductBrand}_${NewProductModel}`;
    setNewProductInternalRef(internalRef);
  };

  const EditInternalReference = () => {
    const internalRef = `${EditProductCategoryUUID}_${EditProductSubCategorieUUID}_${EditProductBrand}_${EditProductModel}`;
    setEditProductInternalRef(internalRef);
  };

  const handleEditCategoryChange = (event: any) => {
    const selectedOption = event.target.value;
    const [selectedCategoryId, selectedCategoryUuid] =
      selectedOption.split(",");

    setEditProductCategorieID(selectedCategoryId);
    setEditProductCategoryUUID(selectedCategoryUuid);
  };

  // Update internal reference whenever Model or Brand Name changes
  useEffect(() => {
    updateInternalReference();
  }, [
    NewProductModel,
    NewProductBrand,
    NewProductCategorieID,
    NewProductCategoryUUID,
    NewProductSubCategoryUUID,
  ]);

  useEffect(() => {
    EditInternalReference();
  }, [
    productData,
    EditProductCategoryUUID,
    EditProductSubCategorieUUID,
    EditProductBrand,
    EditProductModel,
  ]);

  //
  const handleFilterdSubCategoryChange = async (
    parentCategoryID: number | String
  ) => {
    console.log(parentCategoryID);
    console.log(productSubCategorieData);
    //
    let fillterdSubCategory = productSubCategorieData.filter(
      (value: any) => value.ProductCategorieId == parentCategoryID
    );
    console.log(fillterdSubCategory);
    // let array = [];
    // array.push(fillterdSubCategory);
    setfilterdSubCategorieData(fillterdSubCategory);
  };
  //
  const handleEditSubCategoryChange = (event: any) => {
    const selectedOption = event.target.value;
    const [selectedCategoryId, selectedCategoryUuid] =
      selectedOption.split(",");

    setEditProductSubCategorieID(selectedCategoryId);
    setEditProductSubCategorieUUID(selectedCategoryUuid);
  };

  //
  const handleEditClick = (id: GridRowId) => async (event: any) => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    //setEditID(id.toString);
    //event.preventDefault();
    //const event = new Event;
    try {
      await HandleGetSuppliers(event);
      await HandleGetWarehouses(event);
      await HandleGetProductCategories(event);
      await HandleGetProductSubCategories(event);
    } catch (err) {
      console.log("err");
    }

    const editedRow: any = productData.find((row) => row.id === id);
    const editedProductCategorie: any = ProductCategorie.find(
      (row: any) => row.id === editedRow.ProductCategorieId
    );
    const editedProductSubCategorie: any = EditSubCategoryArray.find(
      (row: any) => row.id === editedRow.ProductSubCategorieId
    );
    console.log("Row=" + editedRow.SupplierId);
    handleOpen();

    try {
      setProductID(editedRow.id);
      setProductName(editedRow.name);
      setEditProductInternalRef(editedRow.internalref);
      setEditProductModel(editedRow.model);
      setEditProductBrand(editedRow.brand_name);
      setEditProductQuantity(editedRow.quantity);
      setEditedProductPurchasedMethod(editedRow.purchase_method);
      setEditProductCostPrice(editedRow.cost_price);
      setEditProductSalePrice(editedRow.sales_price);
      // setProductImage(editedRow.image);
      setEditProductImage(editedRow.image);
      setEditedProductDoc(editedRow.document);
      setEditProductCategorieID(editedRow.ProductCategorieId);
      setEditProductSubCategorieID(editedRow.ProductSubCategorieId);
      setEditProductCategoryUUID(editedProductCategorie.uuid);
      setEditProductSubCategorieUUID(editedProductSubCategorie.uuid);
      setEditProductSupplierID(editedRow.SupplierId);
      setEditProductWarehouseID(editedRow.WarehouseId);
      setproductWarehouseData(editedRow.Product_Warehouses);
      //setProductSupplierA(editedRow.SupplierId);
      setEditedProductDescription(editedRow.description);
      //auther
      setProductCreatedBy(editedRow.createdBy);
      setProductCreatedAt(editedRow.createdAt);
      setProductUpdatedBy(editedRow.updatedBy);
      setProductUpdatedAt(editedRow.updatedAt);
      //
      if (editedRow.document) {
        setProductDocBadge(1);
      } else {
        setProductDocBadge(0);
      }
      if (editedRow.quantity) {
        setProductInventoryBadge(editedRow.quantity);
      } else {
        setProductInventoryBadge(0);
      }
      //
      console.log("ProductWarehouse:" + editedRow.Product_Warehouses);
      handleOpen();
      //
    } catch (err: any) {
      console.log(err);
    }
    // alert(`CID=${EditProductCategorieID} \n CUUID=${EditProductCategoryUUID}`);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      //
      setLoading(true);
      //
      const call = await deleteSingeleProduct({ id: id });
      console.log(call);
      GetProducts();
      notifyDone();
    } catch (err: any) {
      console.log(err);
      notifyErr(err.response.data);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // setRowModesModel({
    //   ...rowModesModel,
    //   [id]: { mode: GridRowModes.View, ignoreModifications: true },
    // });
    // const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };
  //

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "internalref", headerName: "Internal Ref", width: 100 },
    { field: "name", headerName: "Product Name", width: 130 },
    { field: "model", headerName: "Model", width: 130 },
    { field: "brand_name", headerName: "Brand Name", width: 130 },
    { field: "document", headerName: "Product Doc", width: 130 },
    { field: "description", headerName: "Product Description", width: 130 },
    { field: "image", headerName: "Product Image", width: 130 },
    {
      field: "quantity",
      valueGetter: (value, row) => {
        function sumQuantity() {
          let sum = 0;
          if (row.Product_Warehouses) {
            for (let i = 0; i < row.Product_Warehouses.length; i++) {
              sum += row.Product_Warehouses[i].quantity;
            }
          }
          return sum;
        }
        return `${sumQuantity()}`;
      },
      headerName: "On Hand",
      width: 80,
    },
    { field: "cost_price", headerName: "Cost Price", width: 100 },
    { field: "sales_price", headerName: "Sale Price", width: 100 },
    { field: "purchase_method", headerName: "Purchase Method", width: 100 },
    {
      field: "ProductCategorieId",
      headerName: "Product Categorie Id",
      width: 70,
    },
    {
      field: "ProductCategorieUUID",
      type: "string",
      valueGetter: (value, row) => {
        return `${row.ProductCategorie ? row.ProductCategorie.uuid : ""}`;
      },
      headerName: "PC UUID",
      width: 100,
    },
    {
      field: "ProductSubCategorieId",
      headerName: "Product Categorie Id",
      width: 70,
    },
    {
      field: "ProductSubCategorieUUID",
      valueGetter: (value, row) => {
        return `${row.ProductSubCategorie ? row.ProductSubCategorie.uuid : ""}`;
      },
      headerName: "ProductSubCategorieUUID",
      width: 100,
    },
    { field: "SupplierId", headerName: "Supplier ID", width: 70 },
    { field: "WarehouseId", headerName: "Warehouse ID", width: 70 },
    {
      field: "ProductCategorie",
      valueGetter: (value, row) => {
        return `${row.ProductCategorie ? row.ProductCategorie.name : ""}`;
      },
      headerName: "Categorie",
      width: 100,
    },
    {
      field: "ProductSubCategorie",
      valueGetter: (value, row) => {
        return `${row.ProductSubCategorie ? row.ProductSubCategorie.name : ""}`;
      },
      headerName: "SubCategorie",
      width: 100,
    },

    {
      field: "Supplier",
      valueGetter: (value, row) => {
        return `${row.Supplier ? row.Supplier.name : ""}`;
      },
      headerName: "Supplier",
      width: 100,
    },
    {
      field: "Warehouse",
      valueGetter: (value, row) => {
        return `${row.Warehouse ? row.Warehouse.name : ""}`;
      },
      headerName: "Warehouse",
      width: 100,
    },
    {
      field: "Product_Warehouses",
      valueGetter: (value, row) => {
        return `${row.Product_Warehouses ? row.Product_Warehouses : ""}`;
      },
      headerName: "Product Warehouse",
      width: 100,
    },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedBy", headerName: "Last Updated By", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return contextModuleId
          ? [
              CheckModuleEditAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),

              CheckModuleDeleteAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  // onClick={handleDeleteClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
            ]
          : [];
      },
    },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   type: "number",
    //   width: 90,
    // },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
    // },
  ];
  //

  const GetProducts = async () => {
    try {
      var req: any = await getAllProduct();
      setproductData(req.data);
      console.log(req.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetProducts();
  }, []);

  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h3
            className="moduleName"
            onClick={(event) => {
              navigate(".");
            }}
          >
            Product For Sale
          </h3>
        </div>
        <div className="moduleMngActionDiv">
          {contextModuleId && CheckModuleCreateAccess(contextModuleId) ? (
            <button
              className="moduleCreateBtn"
              onClick={(event) => {
                navigate("create");
              }}
            >
              New Product
            </button>
          ) : (
            // <div className="AppCard">Request The Admin for Log Modul</div>
            "Access denied"
          )}
          {CheckAdminAccess() ? (
            JSON.parse(
              sessionStorage.get("product_edit_approval_required") || "false"
            ) ? (
              <Button
                onClick={(event) => {
                  navigate("productEditREQUESTS");
                }}
              >
                Approve Edit
              </Button>
            ) : null
          ) : null}
        </div>
      </div>
      <div className="moduleDisplay">
        <Routes>
          <Route index element={<ListView />} />
          <Route path="/create" element={<CreateView />} />
          <Route path="/update" element={<UpdateView />} />
          <Route path="productEditREQUESTS" element={<ProductEditRequests />} />
          <Route path="productEditApproval" element={<ProductEditApproval />} />
        </Routes>
      </div>
    </div>
  );
}
