import React, { useEffect, useState } from "react";
import "./InventorySetting.css";
import { sessionStorage } from "es-storage";
//ant design
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";
import { Spin } from "antd";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateTenantPreference } from "../../../API/tenantAPI";
//

const InventorySetting = () => {
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  const [isChecked, setIsChecked] = useState(false); // Initially false

  // On mount, retrieve the value from sessionStorage
  useEffect(() => {
    const storedValue = sessionStorage.get("product_edit_approval_required");
    if (storedValue !== null) {
      if (storedValue === "true") {
        setIsChecked(true); // Parse and set the boolean value
      } else {
        setIsChecked(false); // Parse and set the boolean value}
      }
    }
  }, []); // Run only once on mount

  //
  const notify_error = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  const notify_success = async (message: any) =>
    toast.success(`Message: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });
  //

  // Handle checkbox change
  const onChange_Product_Edit_Approval_Required: CheckboxProps["onChange"] =
    async (e) => {
      try {
        //
        setLoading(true);
        //
        const checkedValue = e.target.checked;
        setIsChecked(checkedValue); // Update the UI state

        // Update sessionStorage
        sessionStorage.set(
          "product_edit_approval_required",
          JSON.stringify(checkedValue) // Store as a string
        );

        await updateTenantPreference();

        notify_success("Prefrencees Updated Successfully Refresh The Page");
      } catch (error) {
        console.error("Error updating preference:", error);
        notify_error(error);
      } finally {
        setLoading(false); // Set loading to false after request completion or error
      }
    };
  return (
    <div>
      <ToastContainer />
      <Spin spinning={loading}>
        <div>
          <Checkbox
            onChange={onChange_Product_Edit_Approval_Required}
            checked={isChecked} // Reflect current state
          >
            Product Edit Approval Required
          </Checkbox>
          {isChecked ? (
            <label>
              Appoover{" "}
              <input type="text" defaultValue="Adminstrator" readOnly />
            </label>
          ) : null}
        </div>
      </Spin>
    </div>
  );
};

export default InventorySetting;
