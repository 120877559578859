import React, { useState, useEffect } from "react";
import "./UserMng.css";
import "../../GlobalCss/Module/ModuleView.css";
import { useModuleContext } from "../../Contexts/ModuleContext";
//
import Department from "./Department/Department";
import UsersConfiguration from "./UsersConfiguration/UsersConfiguration";
//
import { sessionStorage } from "es-storage";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
//import { Outlet, Link } from "react-router-dom";
//
//api methods
//import { addUser } from "../../API/userAPI";
//auth middleware
import { CheckModuleCreateAccess } from "../../middleware/authorizationMiddleware";
//pages
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
import UpdateView from "./UpdateView/UpdateView";
import UserTable from "./Tables/userTable";
//notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
//mui
//import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//

const UserMng = () => {
  //
  const navigate = useNavigate();
  //
  const location = useLocation();
  // const moduleId = location.state?.moduleId;
  // Extract module ID from state
  //const [moduleId, setModuleId] = useState(location.state?.moduleId);
  const { contextModuleId, moduleLink } = useModuleContext();

  // useEffect(() => {
  //   if (!moduleId && location.state?.moduleId) {
  //     setModuleId(location.state.moduleId);
  //   }
  // }, [location.state, moduleId]);
  //
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (userRole === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userRole]);
  //
  return (
    <div className="moduleMainDiv">
      <ToastContainer />
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2
            className="moduleName"
            onClick={(event) => {
              navigate(".");
            }}
          >
            Users
          </h2>
        </div>

        <div className="moduleMngActionDiv">
          {contextModuleId && CheckModuleCreateAccess(contextModuleId) ? (
            <button
              className="moduleCreateBtn"
              onClick={() => {
                navigate("create");
              }}
            >
              New User
            </button>
          ) : null}
          {isAdmin ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("./");
              }}
            >
              Users
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null}

          {isAdmin ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("departments");
              }}
            >
              Departments
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null}

          {isAdmin ? (
            <Button
              onClick={(event) => {
                // handleOpen();
                // HandleGetSuppliers(event);
                navigate("configurations");
              }}
            >
              Configuration
            </Button>
          ) : // <div className="AppCard">Request The Admin for Log Modul</div>
          null}
        </div>
      </div>
      <div className="moduleDisplay">
        <Routes>
          {/* <Route index element={<UserTable />}></Route> */}
          <Route index element={<ListView />} />
          <Route path="/create" element={<CreateView />} />
          <Route path="/update" element={<UpdateView />} />
          <Route path="/departments" element={<Department />} />
          <Route path="/configurations" element={<UsersConfiguration />} />
        </Routes>
      </div>
    </div>
  );
};

export default UserMng;
