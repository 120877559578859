import React, { useEffect, useState } from "react";
import { sessionStorage } from "es-storage";
import "./SecuritySetting.css";
import { Checkbox, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateTenantPreference } from "../../../API/tenantAPI";

const SecuritySetting = () => {
  console.log(sessionStorage.get("allow_users_reset_password_at_login"));
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // On mount, retrieve the value from sessionStorage
  useEffect(() => {
    const storedValue = sessionStorage.get(
      "allow_users_reset_password_at_login"
    );
    console.log("Initial stored value:", storedValue); // Debugging
    if (storedValue !== null) {
      setIsChecked(storedValue === "true");
    }
  }, []);

  const notify_error = (message: string) =>
    toast.error(`Error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });

  const notify_success = (message: string) =>
    toast.success(`Message: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });

  // Handle checkbox change
  const onChangeAllow_Users_To_Reset_Password_At_Login = async (e: any) => {
    try {
      setLoading(true);
      const checkedValue = e.target.checked;
      setIsChecked(checkedValue); // Update UI state

      // Update sessionStorage
      sessionStorage.set(
        "allow_users_reset_password_at_login",
        checkedValue.toString()
      );

      // Log after setting to confirm
      console.log(
        "Updated stored value:",
        sessionStorage.get("allow_users_reset_password_at_login")
      );

      await updateTenantPreference(); // Ensure this works as expected
      notify_success("Preferences Updated Successfully. Refresh The Page.");
    } catch (error: any) {
      console.error("Error updating preference:", error);
      notify_error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Spin spinning={loading}>
        <Checkbox
          onChange={onChangeAllow_Users_To_Reset_Password_At_Login}
          checked={isChecked} // Reflect current state
        >
          Allow Users To Reset Password At Login
        </Checkbox>
      </Spin>
    </div>
  );
};

export default SecuritySetting;
