import React, { useState, useEffect } from "react";
import "./UpdateView.css";
import "../../../GlobalCss/Module/UpdateView/UpdateView.css";
import { useLocation, useNavigate } from "react-router-dom";
import { sessionStorage } from "es-storage";
// react toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// ant design
import { Checkbox } from "antd";
import { Tooltip } from "antd";
import { Spin } from "antd";
// API Methods
import {
  updateSingleUser,
  UpdateUserRBAC,
  getSingleUser,
} from "../../../API/userAPI";

const plainOptions = [
  { label: "Create", value: "create" },
  { label: "Read", value: "read" },
  { label: "Update", value: "update" },
  { label: "Delete", value: "delete" },
];

// Define interface for product object
interface User {
  id: number;
  fullName: string;
  email: string;
  phone: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  role: string;
}

const UpdateView = () => {
  //spin state
  const [dataLoading, setDataLoading] = React.useState<boolean>(true);
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();
  //
  const location = useLocation();
  // const moduleId = location.state?.moduleId;
  // Extract module ID from state

  const [routeUserID, setRouteUserId] = useState(location.state?.routeUserId);

  useEffect(() => {
    if (!routeUserID && location.state?.routeUserId) {
      const newRouteUserID = location.state.routeUserId;
      setRouteUserId(newRouteUserID);
      setEditUserID(newRouteUserID);
    }
  }, [location.state, routeUserID]);

  useEffect(() => {
    const fetchData = async () => {
      if (routeUserID) {
        await handleFetchUserData(routeUserID);
        setDataLoading(false);
      }
    };
    fetchData();
  }, [routeUserID]);

  //Edit user
  const [userData, setUserData] = useState<User[]>([]);
  const [EditRecordNote, setEditRecordNote] = useState("");
  const [EditUserID, setEditUserID] = useState("");
  const [EditUserName, setEditUserName] = useState("");
  const [EditUserEmail, setEditUserEmail] = useState("");
  const [TenantModuleList, setTenantModuleList] = useState<any[]>([]);
  const [permissions, setPermissions] = useState([]);
  const [editPermissions, setEditPermissions] = useState([]);

  //   let EditRBACUserData: any[] = [];
  const [EditRBACUserData, setEditRBACUserData] = useState([]);

  //### Methods

  // notifications
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //

  //
  const onRBACChange = (
    moduleId: number,
    checkedValues: any,
    permissionID: any
  ) => {
    setEditPermissions((prevData: any) => {
      const updatedData = prevData.map((item: any) => {
        if (item.moduleID === moduleId) {
          return {
            ...item,
            Create: checkedValues.includes("create"),
            Read: checkedValues.includes("read"),
            Update: checkedValues.includes("update"),
            Delete: checkedValues.includes("delete"),
            ID: permissionID,
          };
        }
        return item;
      });

      // If moduleId is not found in the existing data, add it
      if (!prevData.some((item: any) => item.moduleID === moduleId)) {
        updatedData.push({
          moduleID: moduleId,
          Create: checkedValues.includes("Create"),
          Read: checkedValues.includes("Read"),
          Update: checkedValues.includes("Update"),
          Delete: checkedValues.includes("Delete"),
          ID: permissionID,
        });
      }

      // console.log(
      //   "Updated permissions for module",
      //   moduleId,
      //   "=",
      //   checkedValues
      // );
      //console.log("RBACNewUserData", updatedData);

      return updatedData;
    });
    // console.log("Updated permissions for module", moduleId, "=", checkedValues);
    // console.log("RBACNewUserData", RBACNewUserData);
  };
  //

  const handleFetchUserData = async (id: Number) => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    try {
      var req: any = await getSingleUser(id);
      setUserData(req.data);
      setEditUserName(req.data.fullName);
      setEditUserEmail(req.data.email);
      setEditRecordNote(req.data.recordNote);
      if (!req.data.UserModules || req.data.UserModules <= 0) {
        const error = new Error("Invalid RBAC Loaded");
        throw error;
      }
      setEditRBACUserData(req.data.UserModules);
      // console.log(req);
      //setDataLoading(false);
    } catch (error: any) {
      //console.log(error);
      setDataLoading(true);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
        //console.log("Error", error.message);
      }
    }
  };
  //

  //
  const HandleUpdateUser = async (event: any) => {
    event.preventDefault();
    //alert("debug1");
    setUpdateLoading(false);

    try {
      var req = await updateSingleUser({
        userID: routeUserID,
        userName: EditUserName,
        userEmail: EditUserEmail,
        recordNote: EditRecordNote,
      });
      //console.log(req);
      //GetUsers();
      notifySuccess(req.data.msg);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
        //console.log("Error", error.message);
      }
    } finally {
      setUpdateLoading(false); // Set loading to false after request completion or error
    }
  };
  //

  //
  const HandleUpdateUserRBAC = async (event: any) => {
    event.preventDefault();
    try {
      if (!routeUserID) {
        throw new Error("Invalid User Identifier");
      }
      var req = await UpdateUserRBAC({
        UserId: routeUserID,
        RBAC: editPermissions,
        UpdatedBy: sessionStorage.get("user_name"),
      });
      //console.log(req);
      //GetUsers();
      notifySuccess(req.data.msg);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data);
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        notifyError(error.message);
        //console.log("Error", error.message);
      }
    }
  };
  //
  const [activePage, setActivePage] = useState("subpage1");

  return (
    <div className="ModuleUpdateView">
      <ToastContainer />
      <Spin spinning={dataLoading}>
        <Spin spinning={updateLoading}>
          <form className="ModuleUpdateViewForm" onSubmit={HandleUpdateUser}>
            <div className="ModuleUpdateViewForm_Header">
              <div className="RecordID">
                <h1>{EditUserID}</h1>
              </div>
              <button className="moduleSaveBtn" type="submit">
                Update
              </button>
            </div>
            <div className="ModuleUpdateViewForm_Body">
              <div>
                <label className="userlabels">
                  <Tooltip
                    title="Use Full Name!"
                    color="#333333"
                    placement="bottomLeft"
                  >
                    Name:
                  </Tooltip>
                  <input
                    type="text"
                    placeholder="e.g  Alex Morgan"
                    value={EditUserName}
                    onChange={(event) => setEditUserName(event.target.value)}
                    minLength={2}
                    maxLength={50}
                    required
                  ></input>
                </label>
                <label className="userlabels">
                  <Tooltip
                    title="Used to login to the system. Using a proper email address allows users to receive
                  email notice!"
                    color="#333333"
                    placement="bottomLeft"
                  >
                    Email
                  </Tooltip>
                  <input
                    type="email"
                    placeholder="example@example.com"
                    value={EditUserEmail}
                    onChange={(event) => setEditUserEmail(event.target.value)}
                    minLength={2}
                    maxLength={125}
                    required
                  ></input>
                </label>
              </div>
            </div>
          </form>
          <div className="ModuleCreateViewForm_Bottom">
            <div className="FormViewPage">
              <h3>Pages</h3>
              <>
                {/* Navigation Links */}
                <nav style={{ marginBottom: "20px" }}>
                  <a
                    href="#subpage1"
                    onClick={(e) => {
                      e.preventDefault();
                      setActivePage("subpage1");
                    }}
                    style={{
                      marginRight: "10px",
                      textDecoration:
                        activePage === "subpage1" ? "underline" : "none",
                    }}
                  >
                    Access Rights
                  </a>
                  <a
                    href="#subpage1"
                    onClick={(e) => {
                      e.preventDefault();
                      setActivePage("subpage2");
                    }}
                    style={{
                      marginRight: "10px",
                      textDecoration:
                        activePage === "subpage2" ? "underline" : "none",
                    }}
                  >
                    Password
                  </a>
                </nav>
                {/* Content Based on Active Page */}

                {activePage === "subpage1" && (
                  <form className="FormPage" onSubmit={HandleUpdateUserRBAC}>
                    <h3>User Role Based Access Control</h3>
                    <div className="EditUserModuleRBA">
                      {EditRBACUserData.map((value: any) => (
                        <div key={value.id}>
                          <h5>{value.Module.name}</h5>
                          <>
                            <Checkbox.Group
                              options={plainOptions}
                              defaultValue={
                                value.Role.Permissions[0]
                                  ? Object.keys(
                                      value.Role.Permissions[0]
                                    ).filter(
                                      (key) => value.Role.Permissions[0][key]
                                    )
                                  : []
                              }
                              onChange={(checkedValues) =>
                                onRBACChange(
                                  value.Module.id,
                                  checkedValues,
                                  value.Role.Permissions[0].id
                                )
                              }
                            />
                          </>
                        </div>
                      ))}
                      <button className="AddBtn" type="submit">
                        Update RBAC
                      </button>
                    </div>
                  </form>
                )}
              </>
            </div>
            {/* <textarea
              placeholder="Add a note..."
              className="record_note"
              value={EditRecordNote}
              onChange={(e) => setEditRecordNote(e.target.value)}
            ></textarea> */}
            <ReactQuill
              theme="snow"
              value={EditRecordNote}
              onChange={setEditRecordNote}
            />
          </div>
        </Spin>
      </Spin>
    </div>
  );
};

export default UpdateView;
