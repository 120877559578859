import React, { useState } from "react";
import "./GeneralSetting.css";
import { sessionStorage } from "es-storage";
// import { updateCompanyInfo } from "../../../API/companyInfoAPI";
import { updateTenantInfo } from "../../../API/tenantAPI";
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GeneralSetting = () => {
  //
  const [CompanyLogo, setCompanyLogo] = useState(
    sessionStorage.get("company_logo")
  );
  //
  const [CompanyName, setCompanyName] = useState(
    sessionStorage.get("company_name")
  );
  const [CompanyTaxID, setCompanyTaxID] = useState(
    sessionStorage.get("company_taxid")
  );
  const [CompanyPhone, setCompanyPhone] = useState(
    sessionStorage.get("company_phone")
  );
  const [CompanyMobile, setCompanyMobile] = useState(
    sessionStorage.get("company_mobile")
  );
  const [CompanyAddress, setCompanyAddress] = useState<string>(
    sessionStorage.get("company_address")
  );
  const [CompanyEmail, setCompanyEmail] = useState(
    sessionStorage.get("company_email")
  );
  const [CompanyWebsite, setCompanyWebsite] = useState(
    sessionStorage.get("company_website") || ""
  );
  const [CompanyPOBox, setCompanyPOBox] = useState(
    sessionStorage.get("company_pobox")
  );
  //notification Methods
  const notify_error = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  const notify_success = (message: any) =>
    toast.success(`Message: \t ${message}`, {
      position: "top-center",
      theme: "colored",
    });
  //
  //Methods
  const Update = async (event: any) => {
    event.preventDefault();
    try {
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      const call = await updateTenantInfo({
        Name: CompanyName,
        Logo: CompanyLogo,
        TaxID: CompanyTaxID,
        Phone: CompanyPhone,
        Mobile: CompanyMobile,
        Address: CompanyAddress,
        Email: CompanyEmail,
        Website: CompanyWebsite,
        POBox: CompanyPOBox,
      });
      console.log(call);
      notify_success("dhsfb");
    } catch (err: any) {
      //console.log(err);
      notify_error(err);
    }
  };
  //

  const handleLogoChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setCompanyLogo(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="Setting1">
      <ToastContainer />
      <div className="CompanyInfoDiv">
        <h5 className="settingHeader">Company Info</h5>
        <form onSubmit={Update}>
          <div className="form-group">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={CompanyName}
              onChange={(event) => setCompanyName(event.target.value)}
            />
          </div>
          <label className="productlabels">
            Company Logo:
            <div>
              <input type="file" accept="image/*" onChange={handleLogoChange} />
              {CompanyLogo && (
                <img
                  src={CompanyLogo}
                  alt="Uploaded"
                  className="ProductPreviewImg"
                  style={{
                    maxWidth: "200px",
                    maxHeight: "200px",
                  }}
                />
              )}
            </div>
          </label>
          <div className="form-group">
            <label htmlFor="companyAddress">Address</label>
            <textarea
              id="companyAddress"
              name="companyAddress"
              value={CompanyAddress}
              onChange={(event) => setCompanyAddress(event.target.value)}
            />
          </div>
          {/* <div className="form-group">
            <label htmlFor="companyCity">City</label>
            <input
              type="text"
              id="companyCity"
              name="companyCity"
              // value={companyInfo.companyCity}
              // onChange={handleChange}
              required
            />
          </div> */}
          {/* <div className="form-group">
            <label htmlFor="companyCountry">Country</label>
            <input
              type="text"
              id="companyCountry"
              name="companyCountry"
              // value={companyInfo.companyCountry}
              // onChange={handleChange}
              required
            />
          </div> */}
          <div className="form-group">
            <label htmlFor="companyWebsite">Website</label>
            <input
              type="text"
              id="companyWebsite"
              name="companyWebsite"
              value={CompanyWebsite}
              onChange={(event) => setCompanyWebsite(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="companyName">Company Email</label>
            <input
              type="text"
              id="companyEmail"
              name="companyEmail"
              value={CompanyEmail}
              onChange={(event) => setCompanyEmail(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="companyPhoneNumber">Phone Number</label>
            <input
              type="tel"
              id="companyPhoneNumber"
              name="companyPhoneNumber"
              value={CompanyPhone}
              onChange={(event) => setCompanyPhone(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="companyMobileNumber">Mobile Number</label>
            <input
              type="tel"
              id="companyMobileNumber"
              name="companyMobileNumber"
              value={CompanyMobile}
              onChange={(event) => setCompanyMobile(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="companyPOBox">POBox</label>
            <input
              type="text"
              id="companyPOBox"
              name="companyPOBox"
              value={CompanyPOBox}
              onChange={(event) => setCompanyPOBox(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="companyTaxID">TaxID</label>
            <input
              type="text"
              id="companyTaxID"
              name="companyTaxID"
              value={CompanyTaxID}
              onChange={(event) => setCompanyTaxID(event.target.value)}
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default GeneralSetting;
