import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addMyTask = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_MYTASK_ADD_ONE}`,
      {
        userID: current_user_id,
        Title: props.Title,
        Status: props.Status || "Draft",
        // Status: "Draft",
        Description: props.Description,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      // alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const getAllMyTasks = async () => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_MYTASK_GET_ALL}/${current_user_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    //console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const updateSingelMyTaskStatus = async (ID: any, Status: any) => {
  // alert(props.id)
  try {
    // alert(
    //   `ID==${props.ID} \n Name==${props.Name} \n Email==${props.Email} \n Address==${props.Address}`
    // );
    var req = await axios.put(
      `${process.env.REACT_APP_UPDATE_MYTASK_STATUS}/${ID}`,
      {
        userID: current_user_id,
        Status: Status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    if ((err.response.status = 404)) {
      // const navigate = useNavigate();
      alert(err);
      // navigate("/", { replace: true });
    }
    throw err;
  }
};

const deleteMyTask = async (recordID: string | Number) => {
  // alert(props.id)
  try {
    if (!current_user_id) {
      const err = new Error("FE: Invalid UserID");
      err.name = "CustomError";
      throw err;
    }

    if (!recordID) {
      const err = new Error("FE: Invalid Record ID");
      err.name = "CustomError";
      throw err;
    }
    const response = await axios.delete(
      `${process.env.REACT_APP_DELETE_MYTASK_STATUS}/${recordID}`,
      {
        data: { userID: current_user_id }, // Pass `userID` in the `data` field
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err: any) {
    console.log(err);
    // if ((err.response.status = 404)) {
    //   // const navigate = useNavigate();
    //   alert(err);
    //   // navigate("/", { replace: true });
    // }
    throw err;
  }
};

export { addMyTask, getAllMyTasks, updateSingelMyTaskStatus, deleteMyTask };
