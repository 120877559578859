import React, { useEffect, useState } from "react";
import "./UpdateView.css";
import "../../../../GlobalCss/Module/UpdateView/UpdateView.css";
//
//
import { sessionStorage } from "es-storage";
//Api
import {
  addProduct,
  getAllProduct,
  getSingleProduct,
  updateSingeleProduct,
  deleteSingeleProduct,
  SaveUpdateSingeleProductRequest,
  getSingleProductEditRequest,
} from "../../../../API/productAPI";
import { getAllProductCategories } from "../../../../API/productcategoriesAPI";
import { getAllProductSubCategories } from "../../../../API/productsubcategoriesAPI ";
import { getAllSupplier } from "../../../../API/supplierAPI";
import { getAllWarehouse } from "../../../../API/warehouseAPI";
// react toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// ant design
import { Checkbox } from "antd";
import { Tooltip } from "antd";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";

interface Product {
  id: number;
  Name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

const UpdateView = () => {
  const location = useLocation();
  //Routed RecordID
  const [routeRecordID, setRouteRecordID] = useState(
    location.state?.routeRecordId
  );

  useEffect(() => {
    if (!routeRecordID && location.state?.routeRecordId) {
      const newRouteUserID = location.state.routeUserId;
      setRouteRecordID(newRouteUserID);
      // setEditUserID(newRouteUserID);
    }
  }, [location.state, routeRecordID]);

  useEffect(() => {
    const fetchData = async () => {
      if (routeRecordID) {
        await load_mutable_data(routeRecordID);
        setDataLoading(false);
      }
    };
    fetchData();
  }, []);

  //API Data States
  const [productData, setproductData] = useState<Product[]>([]);
  const [APIMainProductCategory, setAPIMainProductCategory] = useState<any[]>(
    []
  );
  let ProductCategorieArray: any = [];
  const [APIProductSubCategory, setAPIProductSubCategory] = useState<any[]>([]);
  let ProductSubCategorieArray: any = [];
  const [APIProductSupplier, setAPIProductSupplier] = useState<any[]>([]);
  const [APIProductWarehouse, setAPIProductWarehouse] = useState<any[]>([]);
  //Edit variabls
  const EditSubCategoryArray: any = [];
  const [ProductID, setProductID] = useState(0);
  const [EditProductName, setEditProductName] = useState("");
  const [EditProductInternalRef, setEditProductInternalRef] = useState("");
  const [EditProductCategorieID, setEditProductCategorieID] = useState("");
  const [EditProductSubCategorieID, setEditProductSubCategorieID] =
    useState("");
  const [EditProductSubCategorieUUID, setEditProductSubCategorieUUID] =
    useState("");
  const [EditProductWarehouseID, setEditProductWarehouseID] = useState("");
  const [EditProductModel, setEditProductModel] = useState("");
  const [EditProductBrand, setEditProductBrand] = useState("");
  const [EditProductCategoryUUID, setEditProductCategoryUUID] = useState("");
  const [EditedProductDoc, setEditedProductDoc] = useState("");
  const [EditedProductPurchasedMethod, setEditedProductPurchasedMethod] =
    useState("");
  const [EditedProductDescription, setEditedProductDescription] = useState("");
  const [EditProductQuantity, setEditProductQuantity] = useState("");
  const [EditProductCostPrice, setEditProductCostPrice] = useState("");
  const [EditProductSalePrice, setEditProductSalePrice] = useState("");
  const [EditProductSupplierID, setEditProductSupplierID] = useState("");
  const [EditProductImage, setEditProductImage] = useState("");
  const [EditRecordNote, setEditRecordNote] = useState("");
  const [NewProductDoc, setNewProductDoc] = useState("");
  //

  const [filterdSubCategorieData, setfilterdSubCategorieData] = useState<any[]>(
    []
  );

  //
  const [Relation_Product_Warehouse, setRelation_Product_Warehouse] = useState(
    []
  );
  //

  const [NewProductSupplierID, setNewProductSupplierID] = useState("");
  //
  const [ProductCreatedBy, setProductCreatedBy] = useState("");
  const [ProductCreatedAt, setProductCreatedAt] = useState("");
  const [ProductUpdatedBy, setProductUpdatedBy] = useState("");
  const [ProductUpdatedAt, setProductUpdatedAt] = useState("");
  //
  //spin state
  const [dataLoading, setDataLoading] = React.useState<boolean>(true);
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);
  // notifications
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //
  // Module Methods

  const load_mutable_data = async (id: Number) => {
    try {
      await HandleGetProductSubCategories();
      await HandleGetProductCategories();
      await HandleGetSuppliers();
      await HandleGetWarehouses();

      var req: any = await getSingleProductEditRequest(id);
      const data = req.data || []; // Default to an empty array in case `call.data` is undefined
      console.log(data);
      setproductData(data);
      console.log(productData);

      // Wait for APIMainProductCategory to be populated before proceeding

      // Access the current state of categories and subcategories
      // Using the functional update to ensure you're getting the latest state
      const currentMainProductCategories: any = await new Promise((resolve) => {
        setTimeout(() => resolve(ProductCategorieArray), 0);
      });

      const currentSubProductCategories: any = await new Promise((resolve) => {
        setTimeout(() => resolve(ProductSubCategorieArray), 0);
      });

      // Find the edited product category and subcategory
      const editedProductCategorie: any = currentMainProductCategories.find(
        (row: any) => row.id === data.ProductCategorieId
      );

      console.log("ProductCategorieId:", data.ProductCategorieId);

      const editedProductSubCategorie: any = currentSubProductCategories.find(
        (row: any) => row.id === data.ProductSubCategorieId
      );

      //
      // Check if editedProductCategorie is defined before accessing uuid
      if (editedProductCategorie) {
        setEditProductCategoryUUID(editedProductCategorie.uuid);
      } else {
        console.log(
          "No matching main category found for ID:",
          data.ProductCategorieId
        );
        //setEditProductCategoryUUID(null); // Handle undefined case
      }

      // Check if editedProductSubCategorie is defined before accessing uuid
      if (editedProductSubCategorie) {
        setEditProductSubCategorieUUID(editedProductSubCategorie.uuid);
      } else {
        console.log(
          "No matching subcategory found for ID:",
          data.ProductSubCategorieId
        );
        //setEditProductSubCategorieUUID(null); // Handle undefined case
      }
      //

      setProductID(data.productID);
      setEditProductName(data.name);
      setEditProductInternalRef(data.internalref);
      setEditProductModel(data.model);
      setEditProductBrand(data.brand_name);
      setEditProductQuantity(data.quantity);
      setEditedProductPurchasedMethod(data.purchase_method);
      setEditProductCostPrice(data.cost_price);
      setEditProductSalePrice(data.sales_price);
      // setProductImage(editedRow.image);
      setEditProductImage(data.image);
      setEditedProductDoc(data.document);
      setEditProductCategorieID(data.ProductCategorieId);
      setEditProductSubCategorieID(data.ProductSubCategorieId);
      //
      setEditProductCategoryUUID(editedProductCategorie.uuid);
      setEditProductSubCategorieUUID(editedProductSubCategorie.uuid);
      //
      setEditProductSupplierID(data.SupplierId);
      setEditProductWarehouseID(data.WarehouseId);
      setRelation_Product_Warehouse(data.Product_Warehouses);
      console.log(data.Product_Warehouses);
      setEditedProductDescription(data.description);
      setEditRecordNote(data.recordNote);
      //auther
      if (data.Creater) {
        setProductCreatedBy(data.Creater.fullName);
      }
      setProductCreatedAt(data.createdAt);
      if (data.Updater) {
        setProductUpdatedBy(data.Updater.fullName);
      }

      setProductUpdatedAt(data.updatedAt);
      //
      if (data.document) {
        //setProductDocBadge(1);
      } else {
        //setProductDocBadge(0);
      }
      if (data.quantity) {
        //setProductInventoryBadge(editedRow.quantity);
      } else {
        //setProductInventoryBadge(0);
      }
      //
      //console.log("ProductWarehouse:" + data.Product_Warehouses);
      //handleOpen();
      //
      // setDataLoading(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notifyError(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log("Error", error.message);
        notifyError("Error:" + error.message);
      }
      console.log(error);
      //throw error;
    } finally {
      //setDataLoading(true);
    }
    // alert(`CID=${EditProductCategorieID} \n CUUID=${EditProductCategoryUUID}`);
  };

  const HandleEdit = async (event: any) => {
    event.preventDefault();
    try {
      //
      setUpdateLoading(true);
      //
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      //

      const call = await updateSingeleProduct({
        ID: ProductID,
        InternalRef: EditProductInternalRef,
        Name: EditProductName,
        Model: EditProductModel,
        Brand_Name: EditProductBrand,
        Document: NewProductDoc,
        Description: EditedProductDescription,
        Image: EditProductImage,
        Quantity: EditProductQuantity,
        Cost_Price: EditProductCostPrice,
        Sales_Price: EditProductSalePrice,
        ProductCategorie_ID: EditProductCategorieID,
        ProductSubCategorie_ID: EditProductSubCategorieID,
        Supplier_ID: EditProductSupplierID,
        Purchase_Method: EditedProductPurchasedMethod,
        Warehouse_ID: EditProductWarehouseID,
        recordNote: EditRecordNote,
      });
      console.log(call);
      //GetProducts();
      notifySuccess("");
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notifyError(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        notifyError("Error:" + error.message);
      }
      // console.log(err);
    } finally {
      //
      setUpdateLoading(false);
      //
    }
  };

  const HandleGetSuppliers = async () => {
    //e.preventDefault();
    try {
      const call = await getAllSupplier();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      const formattedSupplier: any = data.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));

      console.log(formattedSupplier);
      setAPIProductSupplier(formattedSupplier);
      //console.log(ProductSupplierA);
      // setDataLoading(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notifyError(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        notifyError("Error:" + error.message);
      }
      console.log(error);
      throw error;
    }
  };

  const HandleGetProductCategories = async () => {
    //e.preventDefault();
    try {
      const call = await getAllProductCategories();
      const data = call.data || []; // Default to an empty array in case `call.data` is undefined

      // Map each product category into the desired format
      const formattedCategories: any = data.map((item: any) => ({
        id: item.id,
        name: item.name,
        uuid: item.uuid,
      }));

      console.log(formattedCategories);
      ProductCategorieArray = formattedCategories;
      setAPIMainProductCategory(formattedCategories);
      //   setAPIMainProductCategory((prevCategories) => {
      //     const newCategories = [...prevCategories, ...formattedCategories];
      //     console.log("Updated APIMainProductCategory:", newCategories); // Check the updated state
      //     return newCategories;
      //   });

      // This correctly merges the existing and new categories
      //   setAPIMainProductCategory((prevCategories) => [
      //     ...prevCategories,
      //     ...formattedCategories,
      //   ]);
      //setAPIMainProductCategory((a) => [...a, formattedCategories]);
      //console.log(ProductCategorieA);
      console.log(APIMainProductCategory);
      //   setDataLoading(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notifyError(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        notifyError("Error:" + error.message);
      }
      console.log(error);
      throw error;
    }
  };

  const HandleGetProductSubCategories = async () => {
    //e.preventDefault();
    try {
      const call: any = await getAllProductSubCategories();
      const data: any[] = call.data;
      const formattedArray: any = data.map((item: any) => ({
        id: item.id,
        name: item.name,
        uuid: item.uuid,
      }));
      //   for (let i = 0; i < data.length; i++) {
      //     EditSubCategoryArray.push({
      //       id: data[i].id,
      //       name: data[i].name,
      //       uuid: data[i].uuid,
      //     });
      //   }
      //console.log(EditSubCategoryArray);
      //setProductSubCategorieData(call.data);
      ProductSubCategorieArray = formattedArray;
      setAPIProductSubCategory(formattedArray);
      //   setDataLoading(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notifyError(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        notifyError("Error:" + error.message);
      }
      console.log(error);
      throw error;
    }
  };

  const HandleGetWarehouses = async () => {
    //e.preventDefault();
    try {
      const call = await getAllWarehouse();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      const formattedArray: any = data.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));

      console.log(formattedArray);
      setAPIProductWarehouse(formattedArray);
      // setDataLoading(false);
    } catch (error: any) {
      //console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notifyError(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        notifyError("Error:" + error.message);
      }
      console.log(error);
      throw error;
    }
  };

  //
  const handleUpdateImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setEditProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  // Function to update internal reference based on Model and Brand Name
  const EditInternalReference = () => {
    const internalRef = `${EditProductCategoryUUID}_${EditProductSubCategorieUUID}_${EditProductBrand}_${EditProductModel}`;
    setEditProductInternalRef(internalRef);
  };

  const handleEditCategoryChange = (event: any) => {
    const selectedOption = event.target.value;
    const [selectedCategoryId, selectedCategoryUuid] =
      selectedOption.split(",");

    setEditProductCategorieID(selectedCategoryId);
    setEditProductCategoryUUID(selectedCategoryUuid);
  };

  const handleEditSubCategoryChange = (event: any) => {
    const selectedOption = event.target.value;
    const [selectedCategoryId, selectedCategoryUuid] =
      selectedOption.split(",");

    setEditProductSubCategorieID(selectedCategoryId);
    setEditProductSubCategorieUUID(selectedCategoryUuid);
  };

  useEffect(() => {
    EditInternalReference();
  }, [
    productData,
    EditProductCategoryUUID,
    EditProductSubCategorieUUID,
    EditProductBrand,
    EditProductModel,
  ]);

  return (
    <div className="ModuleUpdateView">
      <ToastContainer />
      <Spin spinning={dataLoading}>
        <Spin spinning={updateLoading}>
          <form className="ModuleUpdateViewForm" onSubmit={HandleEdit}>
            <div className="ModuleUpdateViewForm_Header">
              <div className="FormTitle">
                <h4>Product Edit Request Form</h4>
              </div>

              <button className="moduleSaveBtn" type="submit">
                Approve Edit Request
              </button>
            </div>
            <div className="EditProductSaleModelDiv">
              <label className="productInternalReflabel">
                Internal Reference:
                <input
                  type="text"
                  placeholder=""
                  value={EditProductInternalRef}
                  minLength={2}
                  maxLength={100}
                  required
                  readOnly
                ></input>
              </label>
              <div className="EditProductSaleModelContainer">
                <div
                  style={{
                    // width: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label className="productlabels">
                    Product ID :
                    <input
                      type="text"
                      placeholder=""
                      value={ProductID}
                      required
                      readOnly
                    ></input>
                  </label>

                  <label className="productlabels">
                    Product Name :
                    <input
                      type="text"
                      placeholder=""
                      value={EditProductName}
                      onChange={(event) =>
                        setEditProductName(event.target.value)
                      }
                      minLength={2}
                      maxLength={100}
                      required
                    ></input>
                  </label>

                  <label className="productlabels">
                    Brand Name:
                    <input
                      type="text"
                      value={EditProductBrand}
                      placeholder="e.g Caterpillar, Ustunel"
                      onChange={(event) =>
                        setEditProductBrand(event.target.value)
                      }
                      minLength={2}
                      maxLength={100}
                      required
                    ></input>
                  </label>

                  <label className="productlabels">
                    Cost Price :
                    <input
                      type="text"
                      placeholder=""
                      value={EditProductCostPrice}
                      onChange={(event) =>
                        setEditProductCostPrice(event.target.value)
                      }
                      min={1}
                      required
                    ></input>
                  </label>

                  <label className="productlabels">
                    Sale Price :
                    <input
                      type="text"
                      placeholder=""
                      value={EditProductSalePrice}
                      onChange={(event) =>
                        setEditProductSalePrice(event.target.value)
                      }
                      min={1}
                      required
                    ></input>
                  </label>
                </div>
                <div className="ProductAuthDiv">
                  <label className="productlabels">
                    Product Category:
                    <select
                      multiple={false}
                      value={`${EditProductCategorieID},${EditProductCategoryUUID}`}
                      onChange={(e) => handleEditCategoryChange(e)}
                      required
                    >
                      <option value="1">Select a Product Category</option>
                      {APIMainProductCategory &&
                        APIMainProductCategory.map((categorie: any) => (
                          <option
                            key={categorie.id}
                            value={`${categorie.id},${categorie.uuid}`}
                          >
                            {categorie.name}
                          </option>
                        ))}
                    </select>
                  </label>
                  <label className="productlabels">
                    Sub Category:
                    <select
                      multiple={false}
                      value={`${EditProductSubCategorieID},${EditProductSubCategorieUUID}`}
                      onChange={(e) => handleEditSubCategoryChange(e)}
                      required
                    >
                      <option value="1">Select Sub Category</option>
                      {APIProductSubCategory &&
                        APIProductSubCategory.map((categorie: any) => (
                          <option
                            key={categorie.id}
                            value={`${categorie.id},${categorie.uuid}`}
                          >
                            {categorie.name}
                          </option>
                        ))}
                    </select>
                  </label>
                  <label className="productlabels">
                    Product Supplier :
                    <select
                      multiple={false}
                      value={EditProductSupplierID}
                      onChange={(event) =>
                        setEditProductSupplierID(event.target.value)
                      }
                      required
                    >
                      <option value="">Select a supplier</option>
                      {APIProductSupplier &&
                        APIProductSupplier.map((supplier: any) => (
                          <option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                          </option>
                        ))}
                    </select>
                  </label>
                  <label className="productlabels">
                    Model :
                    <input
                      type="text"
                      placeholder=""
                      value={EditProductModel}
                      onChange={(event) =>
                        setEditProductModel(event.target.value)
                      }
                      min={1}
                      required
                    ></input>
                  </label>

                  <label className="productlabels">
                    Method of Purchase:
                    <select
                      multiple={false}
                      value={EditedProductPurchasedMethod}
                      onChange={(event) =>
                        setEditedProductPurchasedMethod(event.target.value)
                      }
                      required
                    >
                      <option value="">Select a Method of Purchase</option>
                      <option value="direct purchase">Direct Purchase</option>
                      <option value="import">Import</option>
                    </select>
                  </label>

                  <label className="productlabels">
                    Prodct Image :
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleUpdateImageChange}
                      />
                      {EditProductImage && (
                        <img
                          src={EditProductImage}
                          alt="Uploaded"
                          className="ProductPreviewImg"
                          style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                          }}
                        />
                      )}
                    </div>
                  </label>

                  <label className="productlabels">
                    Product Description:
                    <textarea
                      placeholder="Detail Product Description"
                      value={EditedProductDescription}
                      onChange={(event) =>
                        setEditedProductDescription(event.target.value)
                      }
                    ></textarea>
                  </label>
                </div>
                <div className="ProductAuthDiv">
                  <label className="productlabels">
                    Requested By:
                    <input
                      type="text"
                      placeholder=""
                      value={ProductCreatedBy}
                      readOnly
                    ></input>
                  </label>

                  <label className="productlabels">
                    Created At:
                    <input
                      type="text"
                      placeholder=""
                      value={ProductCreatedAt}
                      readOnly
                    ></input>
                  </label>

                  <label className="productlabels">
                    Last Updated By:
                    <input
                      type="text"
                      placeholder=""
                      value={ProductUpdatedBy}
                      readOnly
                    ></input>
                  </label>

                  <label className="productlabels">
                    Last Updated At:
                    <input
                      type="text"
                      placeholder=""
                      value={ProductUpdatedAt}
                      readOnly
                    ></input>
                  </label>

                  <div className="StockLevel">
                    <div>
                      <h3>Stock Level</h3>
                      <div className="StockColumn">
                        <h4>Warehouse</h4>
                        <h4>Quantity</h4>
                      </div>
                    </div>
                    {Relation_Product_Warehouse &&
                      Relation_Product_Warehouse.map((pw: any) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <label> {pw.Warehouse.name}</label>
                          <label> {pw.quantity}</label>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="ModuleCreateViewForm_Bottom">
              <ReactQuill
                theme="snow"
                value={EditRecordNote}
                onChange={setEditRecordNote}
              />
            </div>
          </form>
        </Spin>
      </Spin>
    </div>
  );
};

export default UpdateView;
