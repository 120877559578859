import React, { useState, useEffect } from "react";
//
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import { Document, Page } from "react-pdf";
// //
// import { Base64 } from "js-base64";
import { useLocation } from "react-router-dom";

const MyDocViewer = () => {
  const location = useLocation(); // Get the location object
  const [selectedDocs, setSelectedDocs] = useState("");

  // useEffect(() => {
  //   // Access the parameters from location.state
  //   const { param1 } = location.state || {};
  //   console.log(param1);
  //   //setSelectedDocs(Base64.decode(param1.data));
  //   setSelectedDocs(param1.data);
  //   // }, [location.state]);
  // }, []);

  // const docs = [
  //   { uri: selectedDocs },
  //   { uri: require("../../../assest/S00029.pdf") }, // Local File
  // ];

  //return <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} />;
  return (
    <div style={{ height: "100" }}>
      {/* {selectedDocs && (
        <embed
          src={`data:application/pdf;base64,${selectedDocs}`}
          type="application/pdf"
          width="100%"
          height="100vh"
        ></embed>
      )} */}
    </div>
  );
  // const [numPages, setNumPages] = useState<number>();
  // const [pageNumber, setPageNumber] = useState<number>(1);

  // function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
  //   setNumPages(numPages);
  // }
  // return (
  //   <div>
  //     <Document file={selectedDocs} onLoadSuccess={onDocumentLoadSuccess}>
  //       <Page pageNumber={pageNumber} />
  //     </Document>
  //     <p>
  //       Page {pageNumber} of {numPages}
  //     </p>
  //   </div>
  // );
};

export default MyDocViewer;
