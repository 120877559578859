import React, { useEffect, useState } from "react";
import "./CreateView.css";
//Api
import {
  addProduct,
  getAllProduct,
  updateSingeleProduct,
  deleteSingeleProduct,
  SaveUpdateSingeleProductRequest,
} from "../../../../API/productAPI";
import { getAllProductCategories } from "../../../../API/productcategoriesAPI";
import { getAllProductSubCategories } from "../../../../API/productsubcategoriesAPI ";
import { getAllSupplier } from "../../../../API/supplierAPI";
import { getAllWarehouse } from "../../../../API/warehouseAPI";
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//antdesign
import { Spin } from "antd";
import { Tooltip } from "antd";
//

// Define interface for product object
interface Product {
  id: number;
  Name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

const CreateView = () => {
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //New Product Variables
  const [NewProductName, setNewProductName] = useState("");
  const [NewProductImage, setNewProductImage] = useState("");
  const [NewProductDoc, setNewProductDoc] = useState("");
  const [NewProductDescription, setNewProductDescription] = useState("");
  const [NewProductQuantity, setNewProductQuantity] = useState("");
  const [NewProductPrice, setNewProductPrice] = useState("");
  const [NewProductCostPrice, setNewProductCostPrice] = useState("");
  const [NewProductPurchasedMethod, setNewProductPurchasedMethod] =
    useState("");
  const [NewProductModel, setNewProductModel] = useState("");
  const [NewProductBrand, setNewProductBrand] = useState("");
  const [NewProductCategoryUUID, setNewProductCategoryUUID] = useState("");
  const [NewProductSubCategoryUUID, setNewProductSubCategoryUUID] =
    useState("");
  const [NewProductInternalRef, setNewProductInternalRef] = useState("");
  const [NewProductDocument, setNewProductDocument] = useState("");

  //
  const [NewProductWarehouseID, setNewProductWarehouseID] = useState("");
  const [ProductWarehouseA, setProductWarehouseA] = useState([]);
  const ProductWarehouse: any = [];
  //
  //
  const [NewProductCategorieID, setNewProductCategorieID] = useState("");
  const [NewProductSubCategorieID, setNewProductSubCategorieID] = useState("");
  const [ProductCategorieA, setProductCategorieA] = useState([]);
  const ProductCategorie: any = [];
  //
  const [NewProductCategory, setNewProductCategory] = useState("");
  const [NewProductSupplierID, setNewProductSupplierID] = useState("");
  const [ProductSupplierA, setProductSupplierA] = useState([]);
  const ProductSupplier: any = [];
  //
  const EditSubCategoryArray: any = [];
  //
  const [productWarehouseData, setproductWarehouseData] = useState<any[]>([]);
  const [productSubCategorieData, setProductSubCategorieData] = useState([]);
  const [filterdSubCategorieData, setfilterdSubCategorieData] = useState<any[]>(
    []
  );
  const [productData, setproductData] = useState<Product[]>([]);
  //note for the current recors
  const [RecordNote, setRecordNote] = useState("");

  //notice methods
  const notifyDefault = (msg: string) =>
    toast(msg || "Default!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyInfo = (msg: string) =>
    toast.info(msg || "Info!", {
      position: "top-right",
      theme: "colored",
    });
  const notifySuccess = (msg: string) =>
    toast.success(msg || "Success!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyWarning = (msg: string) =>
    toast.warn(msg || "Warning!", {
      position: "top-right",
      theme: "colored",
    });
  const notifyError = (msg: string) =>
    toast.error(msg || "Error!", {
      position: "top-right",
      theme: "colored",
    });
  //

  //Model Methods
  const handleAddProduct = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      var req = await addProduct({
        Name: NewProductName,
        InternalRef: NewProductInternalRef,
        Model: NewProductModel,
        Brand_Name: NewProductBrand,
        Purchase_Method: NewProductPurchasedMethod,
        Document: NewProductDocument,
        Description: NewProductDescription,
        Image: NewProductImage,
        Quantity: NewProductQuantity,
        Cost_Price: NewProductCostPrice,
        Sales_Price: NewProductPrice,
        Supplier_ID: NewProductSupplierID,
        Warehouse_ID: NewProductWarehouseID,
        ProductCategorie_ID: NewProductCategorieID,
        ProductSubCategorie_ID: NewProductSubCategorieID,
        Record_Note: RecordNote,
      });
      console.log(req);
      //GetProducts();
      notifySuccess(req.data);
    } catch (err: any) {
      console.log(err);
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      }

      if (err.response.statusText) {
        notifyError(err.response.statusText);
      }
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const HandleGetProductCategories = async () => {
    //e.preventDefault();
    try {
      const call = await getAllProductCategories();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      //console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductCategorie.push({
          id: data[i].id,
          name: data[i].name,
          uuid: data[i].uuid,
        });
      }
      console.log(ProductCategorie);
      setProductCategorieA(ProductCategorie);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetProductSubCategories = async () => {
    //e.preventDefault();
    try {
      const call: any = await getAllProductSubCategories();
      const data: any[] = call.data;
      for (let i = 0; i < data.length; i++) {
        EditSubCategoryArray.push({
          id: data[i].id,
          name: data[i].name,
          uuid: data[i].uuid,
        });
      }
      //console.log(EditSubCategoryArray);
      setProductSubCategorieData(call.data);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetWarehouses = async () => {
    //e.preventDefault();
    try {
      const call = await getAllWarehouse();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductWarehouse.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductWarehouse);
      setProductWarehouseA(ProductWarehouse);
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetSuppliers = async () => {
    // e.preventDefault();
    try {
      const call = await getAllSupplier();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductSupplier.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductSupplier);
      setProductSupplierA(ProductSupplier);
    } catch (err) {
      console.log(err);
    }
  };

  // const GetProducts = async () => {
  //   try {
  //     var req: any = await getAllProduct();
  //     setproductData(req.data);
  //     console.log(req.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const featchPreData = async () => {
    await HandleGetSuppliers();
    await HandleGetWarehouses();
    await HandleGetProductCategories();
    await HandleGetProductSubCategories();
  };
  useEffect(() => {
    ///GetProducts();
    featchPreData();
  }, []);
  //
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setNewProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };
  //
  // Function to update internal reference based on Model and Brand Name
  const updateInternalReference = () => {
    const internalRef = `${NewProductCategoryUUID}_${NewProductSubCategoryUUID}_${NewProductBrand}_${NewProductModel}`;
    setNewProductInternalRef(internalRef);
  };
  // Update internal reference whenever Model or Brand Name changes
  useEffect(() => {
    updateInternalReference();
  }, [
    NewProductModel,
    NewProductBrand,
    NewProductCategorieID,
    NewProductCategoryUUID,
    NewProductSubCategoryUUID,
  ]);
  //
  const handleFilterdSubCategoryChange = async (
    parentCategoryID: number | String
  ) => {
    console.log(parentCategoryID);
    console.log(productSubCategorieData);
    //
    let fillterdSubCategory = productSubCategorieData.filter(
      (value: any) => value.ProductCategorieId == parentCategoryID
    );
    console.log(fillterdSubCategory);
    // let array = [];
    // array.push(fillterdSubCategory);
    setfilterdSubCategorieData(fillterdSubCategory);
  };
  //

  return (
    <div className="ModuleCreateView">
      <ToastContainer />
      <form className="ModuleCreateViewForm" onSubmit={handleAddProduct}>
        <Spin spinning={loading}>
          <div className="ModuleCreateViewForm_Header">
            <div className="FormTitle">
              <h4>New Product Form</h4>
            </div>
            <button className="moduleSaveBtn" type="submit">
              Save
            </button>
          </div>
          <div className="NewProductModelDiv">
            <div>
              <div>
                {/* Product Details Group */}
                <label className="productlabels">
                  <Tooltip
                    title="Unique ID generated from category, brand & model. !"
                    color="#333333"
                    placement="bottomLeft"
                  >
                    <span>Internal Reference:</span>
                  </Tooltip>
                  <input
                    className="InternalRefInput"
                    type="text"
                    placeholder="01_01_Ustunel_S4CR8D03/15"
                    value={NewProductInternalRef}
                    minLength={2}
                    maxLength={100}
                    required
                    readOnly
                  ></input>
                </label>
                <label className="productlabels">
                  Product Name:
                  <input
                    type="text"
                    placeholder="e.g Pump"
                    onChange={(event) => setNewProductName(event.target.value)}
                    minLength={2}
                    maxLength={100}
                    required
                  ></input>
                </label>

                <label className="productlabels">
                  Model:
                  <input
                    type="text"
                    placeholder="S4CR8D03/15"
                    onChange={(event) => setNewProductModel(event.target.value)}
                    minLength={2}
                    maxLength={100}
                    required
                  ></input>
                </label>

                <label className="productlabels">
                  Brand Name:
                  <input
                    type="text"
                    placeholder="e.g Caterpillar, Ustunel"
                    onChange={(event) => setNewProductBrand(event.target.value)}
                    minLength={2}
                    maxLength={100}
                    required
                  ></input>
                </label>
              </div>
            </div>

            {/* Pricing Group */}
            <div className="Group">
              <label className="productlabels">
                <Tooltip
                  title="Price at which the product is sold to customers!"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Sales Price:
                </Tooltip>
                <input
                  type="number"
                  placeholder="100.00"
                  onChange={(event) => setNewProductPrice(event.target.value)}
                  min={1}
                  required
                ></input>
              </label>

              <label className="productlabels">
                <Tooltip
                  title="Price value of the Product!"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Cost Price:
                </Tooltip>
                <input
                  type="number"
                  placeholder="100.00"
                  onChange={(event) =>
                    setNewProductCostPrice(event.target.value)
                  }
                  min={1}
                  required
                ></input>
              </label>

              <label className="productlabels">
                Product Quantity:
                <input
                  type="number"
                  placeholder=""
                  onChange={(event) =>
                    setNewProductQuantity(event.target.value)
                  }
                  min={1}
                  required
                ></input>
              </label>
            </div>

            {/* Category & Sub-Category Group */}
            <div className="group">
              <label className="productlabels">
                <Tooltip
                  title="Product categories found. Set up categories in configuration settings!"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Product Category:
                </Tooltip>
                <select
                  multiple={false}
                  onChange={async (event) => {
                    const selectedOption = event.target.value;
                    const selectedCategoryId = selectedOption.split(",")[0];
                    const selectedCategoryUuid = selectedOption.split(",")[1];

                    setNewProductCategorieID(selectedCategoryId);
                    setNewProductCategoryUUID(selectedCategoryUuid);
                    handleFilterdSubCategoryChange(selectedCategoryId);
                    // setNewProductCategoryUUID(event.target.value[1]);
                    // setNewProductCategorieID(event.target.value[0]);
                    // alert(
                    //   `ID=${event.target.value[0]}\n UUID=${event.target.value[1]}`
                    // );
                  }}
                  required
                >
                  <option value="">Select a Product Categorie</option>
                  {ProductCategorieA.map((categorie: any) => (
                    <option
                      key={categorie.id}
                      value={`${categorie.id},${categorie.uuid}`}
                    >
                      {categorie.name}
                    </option>
                  ))}
                </select>
              </label>

              <label className="productlabels">
                <Tooltip
                  title="Product sub-categories found. Filtred based on main Categories.Set up categories in configuration settings!"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Sub Category:
                </Tooltip>
                <select
                  multiple={false}
                  onChange={(event) => {
                    const selectedOption = event.target.value;
                    const selectedCategoryId = selectedOption.split(",")[0];
                    const selectedCategoryUuid = selectedOption.split(",")[1];

                    setNewProductSubCategorieID(selectedCategoryId);
                    setNewProductSubCategoryUUID(selectedCategoryUuid);
                  }}
                  required
                >
                  <option value="">Select Sub Categorie</option>
                  {filterdSubCategorieData.map((categorie: any) => (
                    <option
                      key={categorie.id}
                      value={`${categorie.id},${categorie.uuid}`}
                    >
                      {categorie.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            {/* Supplier & Warehouse Group */}
            <div className="NewProductImgContainer">
              <label className="productlabels">
                Product Supplier:
                <select
                  multiple={false}
                  onChange={(event) =>
                    setNewProductSupplierID(event.target.value)
                  }
                  required
                >
                  <option value="">Select a supplier</option>
                  {ProductSupplierA.map((supplier: any) => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))}
                </select>
              </label>

              <label className="productlabels">
                Warehouse:
                <select
                  multiple={false}
                  onChange={(event) =>
                    setNewProductWarehouseID(event.target.value)
                  }
                  required
                >
                  <option value="">Select a Warehouse</option>
                  {ProductWarehouseA.map((Warehouse: any) => (
                    <option key={Warehouse.id} value={Warehouse.id}>
                      {Warehouse.name}
                    </option>
                  ))}
                </select>
              </label>

              <label className="productlabels">
                Method of Purchase:
                <select
                  multiple={false}
                  onChange={(event) =>
                    setNewProductPurchasedMethod(event.target.value)
                  }
                  required
                >
                  <option value="">Select a Method of Purchase</option>
                  <option value="direct purchase">Direct Purchase</option>
                  <option value="import">Import</option>
                </select>
              </label>
            </div>

            {/* Media Group */}
            <div className="media-details">
              <label className="productlabels">
                Product Image:
                {/* <input
                            type="file"
                            placeholder=""
                            onChange={(event) =>
                              setProductImage(event.target.value)
                            }
                            accept=".jpeg, .png, .jpg"
                          ></input> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    multiple={false}
                    name="Add Image"
                  />
                  {NewProductImage && (
                    <img
                      src={NewProductImage}
                      alt="Uploaded"
                      className="ProductPreviewImg"
                      style={{
                        maxWidth: "200px",
                        maxHeight: "200px",
                      }}
                    />
                  )}
                </div>
              </label>

              <label className="productlabels">
                Product Description:
                <textarea
                  placeholder="Detail Product Description 
                      Ustunel 4” Submersible Pump"
                  onChange={(event) =>
                    setNewProductDescription(event.target.value)
                  }
                ></textarea>
              </label>
            </div>
          </div>
          <ReactQuill theme="snow" onChange={setRecordNote} />
        </Spin>
      </form>
    </div>
  );
};

export default CreateView;
