import React from "react";
import { useDroppable } from "@dnd-kit/core";

export const Droppable = ({ id, children }: { id: string; children: React.ReactNode }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        padding: "20px",
        minHeight: "150px",
        border: "2px dashed black",
        backgroundColor: isOver ? "lightblue" : "white",
        margin: "10px",
      }}
    >
      {children}
    </div>
  );
};
