import * as React from "react";
//pages
import ListView from "./ListView/ListView";

export default function ProductTable() {
  //
  return (
    <div>
      <h3>Product Edit Requests</h3>
      <ListView />
    </div>
  );
}
